<template>
  <div class="form-group">
    <div class="form-label" :class="{ 'form-label--required': required }">{{ label }}</div>
    <form-field
      class="form-field--files form-field--2 form-field--s form-field--primary"
      :class="{ 'has-error': error || (required && !hasFiles) }"
    >
      <div class="file-attach">
        <div class="file-attach__main">
          <template v-if="file || error">
            <div class="file-attach__cover" :class="{ 'file-attach__cover--error': error }">
              <div class="loader">
                <SvgIcon v-if="error" name="warning" :size="24" />
                <SvgClr v-else-if="file" name="loader" :size="24" />
              </div>
            </div>
          </template>
          <div class="file-attach__placeholder">{{ placeholder }}</div>
        </div>
        <label class="file-attach__label">
          <input type="file" class="file-attach__input" @change="uploadFile" ref="input" accept=".sig" />
          <span class="file-attach__btn">Обзор</span>
        </label>
      </div>
    </form-field>
    <div v-if="error" class="error-text error-text--right">
      {{ error }}
    </div>
    <div v-if="required && !hasFiles" class="error-text error-text--right">Пожалуйста, приложите документы</div>
  </div>
</template>

<script>
import FormField from '@/components/FormField';
import SvgClr from '@/components/SvgClr';
import SvgIcon from '@/components/SvgIcon';
import { Applications } from '@/repositories';
import { ValidationError } from '@/exceptions';
export default {
  name: 'ApplicationFileUploader',
  components: { FormField, SvgClr, SvgIcon },
  data() {
    return {
      file: null,
      error: null,
    };
  },
  computed: {
    placeholder() {
      return this.file ? this.file.name : 'Прикрепите файл';
    },
  },
  methods: {
    uploadFile(event) {
      const file = event.target.files[0];
      this.file = file;
      if (file == null) {
        return;
      }
      this.error = null;
      Applications.addCopy(this.applicationId, file)
        .then(() => this.$emit('uploaded'))
        .then(() => (this.file = null))
        .then(() => (this.$refs.input.value = ''))
        .catch(this.handleUploadError);
    },
    handleUploadError(error) {
      if (!(error instanceof ValidationError)) {
        this.error = 'Не удалось сохранить файл. Попробуйте, пожалуйста позже!';
        throw error;
      }
      this.error = error.getAllErrors().join(' ');
    },
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    required: Boolean,
    label: String,
    hasFiles: Boolean,
  },
  emits: ['uploaded'],
};
</script>
