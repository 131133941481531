<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="tbl-wrap">
            <table class="tbl-list">
              <thead>
                <tr>
                  <th>Филиал</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="branch in branches" :key="branch.id" class="tbl-link">
                  <td>
                    <div class="tbl-info">
                      <div class="tbl-info__name">{{ branch.name }}</div>
                      <div class="tbl-info__description">{{ branch.legalAddress }}</div>
                    </div>
                  </td>
                  <td class="align-center">
                    <switch-input v-model="selectedBranches" :value="branch.id" :disabled="!editable.editable" size="s" type="checkbox"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="editable.editable" class="main__footer main__footer--sticky">
    <div class="line line--jcfe">
      <div class="line__item">
        <custom-button label="Сохранить" @click="submit"/>
      </div>
    </div>
  </div>
</template>

<script>
import SwitchInput from '@/components/SwitchInput';
import CustomButton from '@/components/CustomButton';
import { unique } from '@/utils/helpers/unique';
import { Applications } from '@/repositories';
import { pluck } from '@/utils/helpers';
export default {
  name: 'ApplicationBranches',
  components: { SwitchInput, CustomButton },
  created() {
    this.fetchBranches();
  },
  watch: {
    id() {
      this.fetchBranches();
    },
  },
  data() {
    return {
      branches: null,
      selectedBranches: [],
    };
  },
  methods: {
    async fetchBranches() {
      const availableBranches = await Applications.getLicenseBranches(this.id);
      const selectedBranches = await Applications.getBranches(this.id);
      this.branches = unique([...availableBranches, ...selectedBranches], 'id');
      this.selectedBranches = pluck(selectedBranches, 'id');
    },
    submit() {
      Applications
        .updateBranches(this.id, this.selectedBranches)
        .catch(() => window.alert('Не удалось изменить список филиалов. Попробуйте, пожалуйста, позже!'));
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    editable: Object /* Костыль поскольку примитив не прокидывается через router-view */,
  },
};
</script>
