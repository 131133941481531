import { computed } from 'vue';

export function getApplicationsReasons(applications) {
  return computed(() => {
    const reasons = applications.value.reduce((acc, curr) => acc.concat(curr.reasons), []);
    return uniqueBy(reasons, 'id');
  });
}

function uniqueBy(array, key) {
  const seenValues = new Set();
  return array.filter(item => {
    const keyValue = item[key];
    if (seenValues.has(keyValue)) {
      return false;
    }

    seenValues.add(keyValue);
    return true;
  })
}