<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--half">
                  <input-text label="Номер заявления" v-model="filters.number" />
                </div>
                <div class="col col--half">
                  <custom-select
                    label="Статус запроса"
                    placeholder="Выберите статус"
                    v-model="filters.status"
                    :options="statuses"
                  />
                </div>
                <div class="col col--half">
                  <input-text
                    label="Дата регистрации заявления от"
                    is-date
                    button="calendar"
                    v-model="filters.applicationCreatedAt.from"
                  />
                </div>
                <div class="col col--half">
                  <input-text
                    label="до"
                    is-date
                    button="calendar"
                    v-model="filters.applicationCreatedAt.to"
                  />
                </div>
                <div class="col col--full">
                  <custom-select
                    label="Все типы заявлений"
                    placeholder="Выберите тип заявления"
                    v-model="filters.applicationType"
                    :options="applicationsTypes"
                  />
                </div>
                <div class="col col--full">
                  <div class="line line--jcfe">
                    <div class="line__item">
                      <custom-button :type="4" label="Сбросить" @click="resetFilters" />
                    </div>
                    <div class="line__item">
                      <custom-button label="Применить" @click="filterRequests" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th>№ заявления</th>
                      <th>Дата регистрации</th>
                      <th>Тип заявления</th>
                      <th>Дата запроса</th>
                      <th>Срок исполнения запроса</th>
                      <th>Статус запроса</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="filteredRequests && !filteredRequests.length">
                      <tr class="tbl-link">
                        <td colspan="5" class="align-center">Запросы не найдены</td>
                      </tr>
                    </template>
                    <template v-if="filteredRequests && filteredRequests.length">
                      <tr
                        class="tbl-link"
                        v-for="request in filteredRequests"
                        :key="request.id"
                        @click="redirectToRequest(request.id)"
                      >
                        <td>{{ request.application.registrationNumber }}</td>
                        <td>{{ $filters.formatDate(request.application.createdAt) }}</td>
                        <td>{{ request.application.type }}</td>
                        <td>{{ $filters.formatDate(request.requestedAt) }}</td>
                        <td>{{ $filters.formatDate(request.completeUntil) }}</td>
                        <td>{{ request.status.name }}</td>
                        <td>
<!--                          TODO поменять на компонент-->
                          <a :href="request.filePath" v-if="request.filePath" @click.stop>
                            <button class="link link--error" type="button">
                              <svg-icon name="pdf" :size="16"/>
                            </button>
                          </a>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main__footer main__footer--sticky">
        <div class="line line--jcfe">
          <div class="line__item">
            <report-error-button />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import CustomSelect from '@/components/CustomSelect';
import ReportErrorButton from '@/components/ReportErrorButton';
import { Request } from '@/repositories/user/Request';
import { unique } from '@/utils/helpers/unique';
import SvgIcon from '@/components/SvgIcon';

export default {
  name: 'Requests',
  components: { ReportErrorButton, InputText, CustomSelect, CustomButton, SvgIcon },
  created() {
    Request.all().then((requests) => (this.filteredRequests = this.requests = requests));
  },
  data() {
    return {
      requests: null,
      filteredRequests: null,
      filters: {
        number: null,
        status: null,
        applicationCreatedAt: {
          from: null,
          to: null,
        },
        applicationType: null,
      },
    };
  },
  computed: {
    statuses() {
      if (!this.requests) {
        return [];
      }

      const statuses = this.requests.map((request) => request.status.name);
      return unique(statuses).map((status) => ({ value: status }));
    },
    applicationsTypes() {
      if (!this.requests) {
        return [];
      }
      const types = this.requests.map((request) => request.application.type);
      return unique(types).map((type) => ({ value: type }));
    },
  },
  methods: {
    redirectToRequest(requestId) {
      this.$router.push({ name: 'user.request', params: { id: requestId } });
    },
    filterRequests() {
      this.filteredRequests = this.requests.filter((request) => {
        const {
          number,
          status,
          applicationType,
          applicationCreatedAt: { from, to },
        } = this.filters;
        if (number && request.application.registrationNumber !== number) {
          return false;
        }

        if (status && request.status.name !== status) {
          return false;
        }

        if (applicationType && request.application.type !== applicationType) {
          return false;
        }

        if (from && new Date(request.application.createdAt).getTime() < from.getTime()) {
          return false;
        }

        if (to) {
          to.setDate(to.getDate() + 1);
          if (new Date(request.application.createdAt) > to) {
            return false;
          }
        }

        return true;
      });
    },
    resetFilters() {
      Object.assign(this.filters, {
        number: null,
        status: null,
        applicationCreatedAt: {
          from: null,
          to: null,
        },
        applicationType: null,
      });
      this.filteredRequests = this.requests;
    },
  },
};
</script>