<template>
  <div class="block">
    <div class="block__top">
      <div class="block-title">Лицензия на работу с государственной тайной</div>
    </div>
    <div class="block__main">
      <div class="row row--aife">
        <div class="col col--fourth">
          <input-text :is-disabled="disabled" label="Регистрационный номер" v-model="form.number" />
        </div>
        <div class="col col--fourth">
          <input-text
            :is-disabled="disabled"
            label="Серия, номер бланка лицензии"
            v-model="form.series"
          />
        </div>
        <div class="col col--fourth">
          <input-text
            :is-disabled="disabled"
            label="Дата выдачи лицензии"
            is-date
            icon="calendar"
            v-model="form.issuedAt"
          />
        </div>
        <div class="col col--fourth">
          <input-text
            :is-disabled="disabled"
            label="Срок действия лицензии"
            is-date
            icon="calendar"
            v-model="form.expiresAt"
          />
        </div>
      </div>
    </div>
    <div class="block__bottom">
      <div class="line">
        <div class="line__item line__item--mra">
          <custom-button label="Сохранить" @click="$emit('submit', form)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
export default {
  name: 'StateSecretLicense',
  props: {
    disabled: Boolean,
    number: String,
    series: String,
    issuedAt: Date,
    expiresAt: Date,
  },
  emits: ['submit'],
  watch: {
    number() {
      this.form.number = this.number;
    },
    series() {
      this.form.series = this.series;
    },
    issuedAt() {
      this.form.issuedAt = this.issuedAt;
    },
    expiresAt() {
      this.form.expiresAt = this.expiresAt;
    },
  },
  data() {
    return {
      form: {
        number: this.number,
        series: this.series,
        issuedAt: this.issuedAt,
        expiresAt: this.expiresAt,
      },
    };
  },
  components: { CustomButton, InputText },
};
</script>
