import axios from '@/api/axios';
import { ValidationError } from '@/exceptions';

export const Expertises = {
  /** @return {Promise<Expertise[]>} */
  async get() {
    const response = await axios.get('expert/expertises');
    return response.data.data;
  },
  async getDescription(expertiseId) {
    const response = await axios.get(`expert/expertises/${expertiseId}/description`);
    return response.data.expertise;
  },
  /** @return {Promise<ExpertiseExpert[]>} */
  async getExperts(expertiseId) {
    const response = await axios.get(`expert/expertises/${expertiseId}/experts`);
    return response.data.data;
  },
  /** @return {Promise<ExpertiseProperties>} */
  async getProperties(expertiseId) {
    const response = await axios.get(`expert/expertises/${expertiseId}/properties`);
    return response.data.data;
  },
  /**
   * @param {string} expertiseId
   * @return {Promise<ExpertiseTask>}
   */
  async getTask(expertiseId) {
    const response = await axios.get(`expert/expertises/${expertiseId}/task`);
    return response.data.task;
  },
  async removeReport(expertiseId, reportId) {
    await axios.delete(`expert/expertises/${expertiseId}/documents/${reportId}/delete`);
  },
  async addReport(expertiseId, form) {
    await axios.post(`expert/expertises/${expertiseId}/upload-report`, form);
  },
  /**
   * @param {string} expertiseId
   * @return {Promise<ExpertiseDocument[]>}
   */
  async getDocumentsForExpert(expertiseId) {
    const response = await axios.get(`expert/expertises/${expertiseId}/documents-for-expert`);
    return response.data.data;
  },
  /**
   * @param {string} expertiseId
   * @return {Promise<ExpertiseDocument[]>}
   */
  async getExpertDocuments(expertiseId) {
    const response = await axios.get(`expert/expertises/${expertiseId}/expert-documents`);
    return response.data.data;
  },
  /**
   * @param  {string} expertiseId
   * @param {string[]} documentIds
   * @param {object} signer
   * @param {string} signer.number
   * @param {string} signer.subject
   * @param {string} signer.dateFrom
   * @param {string} signer.dateUntil
   * @returns {Promise<{id: string, hash: string}[]>}
   */
  async getDocumentsHashes(expertiseId, documentIds, signer) {
    const response = await axios.get(`expert/expertises/${expertiseId}/documents/hashes`, {
      params: {
        ids: documentIds,
        signer,
      },
    });
    return response.data;
  },
  /**
   * @param {string} expertiseId
   * @param {{document_id: string, signature: string}} documentsIdsWithSignatures
   * @returns {Promise<void>}
   */
  async signDocuments(expertiseId, documentsIdsWithSignatures) {
    await axios.post(`expert/expertises/${expertiseId}/documents/signatures`,
        {documents_signatures: documentsIdsWithSignatures}
    );
  },
  async removeDocument(expertiseId, documentId) {
    await axios.delete(`expert/expertises/${expertiseId}/documents/${documentId}/delete`);
  },
  /** @return {Promise<ExpertiseDocumentType[]>}*/
  async getDocumentTypes() {
    const response = await axios.get('expert/expertises/document-types');
    return response.data.data;
  },
  /**
   *
   * @param {string} expertiseId
   * @param {CreateExpertiseDocumentForm} form
   * @return {Promise<void>}
   */
  async addDocument(expertiseId, form) {
    try {
      await axios.post(`expert/expertises/${expertiseId}/documents`, form);
    } catch (error) {
      if (error.response?.status !== 422) {
        throw error;
      }
      throw new ValidationError(error.response.data.errors);
    }
  },
  /**
   * @param {string} expertiseId
   * @return {Promise<ExpertiseApplicationDocuments>}
   */
  async getApplicationDocuments(expertiseId) {
    const response = await axios.get(`expert/expertises/${expertiseId}/application-documents`);
    return response.data.data;
  },
  /**
   * @param expertiseId
   * @param comment
   * @returns {Promise<boolean>}
   */
  async refuse(expertiseId, comment = null) {
    try {
      await axios.post(
          `expert/expertises/${expertiseId}/refusal`,
          { comment }
      );
      return true;
    } catch {
      return false;
    }
  },
  /**
   * @param expertiseId
   * @param file
   * @param comment
   * @returns {Promise<boolean>}
   */
  async accept(expertiseId, file, comment = null) {
    const data = new FormData();
    data.append('file', file);
    /* FormData accepts only strings and files, other values are converted to strings */
    if (comment) data.append('comment', comment);

    try {
      await axios.post(
          `expert/expertises/${expertiseId}/consent`,
          data
      );
      return true;
    } catch {
      return false;
    }
  },
};

/**
 * @typedef Expertise
 * @property {string} id
 * @property {string|null} number
 * @property {string|null} type
 * @property {string|null} startsAt
 * @property {string|null} endsAt
 * @property {ExpertiseOrganization} organization
 * @property {boolean} [expertIsChairman]
 */

/**
 * @typedef ExpertiseOrganization
 * @property {string|null} name
 * @property {string|null} address
 * @property {object} [contacts]
 * @property {string|null} contacts.phoneNumbers
 * @property {string|null} contacts.faxes
 * @property {string|null} contacts.emails
 * @property {string|null} contacts.site
 * @property {object} [head]
 * @property {string|null} head.firstName
 * @property {string|null} head.surname
 * @property {string|null} head.patronymic
 * @property {string|null} head.position
 * @property {string|null} head.phoneNumbers
 * @property {string|null} head.faxes
 * @property {string|null} head.emails
 */

/**
 * @typedef ExpertiseExpert
 * @property {string} firstName
 * @property {string} surname
 * @property {string} patronymic
 * @property {string} fullName
 * @property {string} role
 * @property {string|null} [worksAt]
 * @property {string|null} position
 * @property {ExpertContacts} [contacts]
 */

/**
 * @typedef ExpertContacts
 * @property {string|null} phoneNumber
 * @property {string|null} email
 * @property {string|null} fax
 */

/**
 * @typedef ExpertiseProperties
 * @property {string} number
 * @property {ExpertiseOrganization} organization
 * @property {boolean} noLeave - Экспертиза без выезда
 * @property {string} startsAt
 * @property {string} endsAt
 * @property {*} application
 * @property {boolean} responseGiven
 */

/**
 * @typedef ExpertiseTask
 * @property {ExpertiseOrganization|null} organization
 * @property {string|null} chairman
 * @property {ExpertiseContract|null} contract
 * @property {DocumentFile|null} conclusion
 * @property {string|null} curator
 * @property {ExpertiseEducationalProgram[]} educationPrograms
 *
 */

/**
 * @typedef ExpertiseContract
 * @property {string|null} number
 */

/**
 * @typedef DocumentFile
 * @property {string} id
 * @property {string|null} is_link
 * @property {string|null} name
 * @property {string|null} path
 */

/**
 * @typedef ExpertiseEducationalProgram
 * @property {string} id
 * @property {string|null} egsCode
 * @property {string|null} egsName
 * @property {string|null} code
 * @property {string|null} name
 * @property {string|null} educationLevel
 * @property {string|null} organizationId
 * @property {DocumentFile|null} report
 */

/**
 * @typedef ExpertiseDocument
 * @property {string} id
 * @property {string|null} createdAt
 * @property {string|null} number
 * @property {string|null} comment
 * @property {ExpertiseDocumentFile|null} file
 * @property {ExpertiseDocumentFile|null} file
 */

/**
 * @typedef ExpertiseDocumentFile
 * @property {string|null} name
 * @property {string|null} path
 * @property {string|null} createdAt
 */

/**
 * @typedef ExpertiseDocumentType
 * @property {string} id
 * @property {string|null} name
 */

/**
 * @typedef {FormData} CreateExpertiseDocumentForm
 * @property {string|number} documentType
 * @property {string|Date} uploadedAt
 * @property {File} file
 * @property {string|null} [comment]
 */


/**
 * @typedef ExpertiseApplicationDocuments
 * @property {DocumentType[]} documentTypes
 * @property {DocumentGroupType[]} documentGroupTypes
 */

/**
 * @typedef DocumentType
 * @property {string} id
 * @property {string|null} name
 * @property {DocumentFile[]} applicationFiles
 */

/**
 * @typedef DocumentGroupType
 * @property {string} id
 * @property {string|null} name
 * @property {DocumentGroup[]} documents
 */

/**
 * @typedef DocumentGroup
 * @property {string} id
 * @property {string|null} fileName
 * @property {string|null} filePath
 */
