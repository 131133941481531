<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">

          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--half">
                  <input-text
                    label="Дата формирования от"
                    is-date
                    button="calendar"
                    v-model="filters.dateFrom"
                  />
                </div>
                <div class="col col--half">
                  <input-text label="до" is-date button="calendar" v-model="filters.dateTo" />
                </div>
                <div class="col col--half">
                  <custom-select
                    label="Статус заявления"
                    placeholder="Все статусы"
                    :options="statuses"
                    v-model="filters.status"
                  />
                </div>
                <div class="col col--half">
                  <input-text label="Рег. номер" v-model="filters.applicationNumber" />
                </div>
                <div class="col col--full">
                  <div class="line line--jcfe">
                    <div class="line__item">
                      <custom-button :type="4" label="Сбросить" @click="resetFilters" />
                    </div>
                    <div class="line__item">
                      <custom-button label="Применить" @click="applyFilters" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th v-for="column in $options.columns" :key="column">{{ column }}</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr v-if="loaded && !notifications.length">
                    <td :colspan="$options.columns.length" class="align-center">Уведомления не найдены</td>
                  </tr>
                  <template v-else>
                    <tr
                      v-for="(notification, index) in notifications"
                      :key="notification.id"
                      class="tbl-link"
                    >
                      <td>{{ index + 1 }}</td>
                      <td>{{ notification.applicationNumber }}</td>
                      <td>
                        <a
                          :href="notification.file"
                        >{{ notification.number }}</a
                        >
                      </td>
                      <td>{{ notification.status }}</td>
                      <td>
                        {{
                          notification.createdAt && this.$filters.formatDate(notification.createdAt)
                        }}
                      </td>
                      <td>
                        {{
                          notification.signedAt && this.$filters.formatDate(notification.signedAt)
                        }}
                      </td>
                      <td>
                        {{ notification.sentAt && this.$filters.formatDate(notification.sentAt) }}
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { Applications } from '@/repositories';
import InputText from '@/components/InputText';
import CustomSelect from '@/components/CustomSelect';
import CustomButton from '@/components/CustomButton';
export default {
  name: 'Notifications',
  components: { InputText, CustomButton, CustomSelect },
  columns: [
    '№',
    'Рег. номер',
    'Номер уведомления',
    'Статус заявления',
    'Дата формирования',
    'Дата подписания',
    'Дата отправки',
  ],
  setup() {
    const notifications = ref([]);
    /* Костыль для отображения информации об отсутствии данных после их скачивания */
    const loaded = ref(false);
    Applications.getNotifications().then((items) => {
      /* Костыльно прячем неподписанные файлы. Подписанными считаем только архивы с окончанием .zip
       * Для корректной реализации нужно проверять signedAt(DateSigning) в БД и не писать туда дату без подписи по факту */
      notifications.value = items.filter(
        (notification) => notification.file && notification.file.slice(-4) === '.zip'
      );
      loaded.value = true;
    });

    const statuses = computed(() => {
      const uniqueStatuses = notifications.value.reduce((statuses, notification) => {
        if (notification.status != null && !statuses.has(notification.status)) {
          statuses.add(notification.status);
        }

        return statuses;
      }, new Set());
      return [...uniqueStatuses].map((status) => ({ value: status }));
    });

    const defaultFilters = {
      dateFrom: null,
      dateTo: null,
      status: null,
      applicationNumber: null,
    };

    const filters = ref({ ...defaultFilters });
    const filteredNotifications = ref(notifications.value);
    watch(notifications, () => (filteredNotifications.value = notifications.value));

    const resetFilters = () => {
      filters.value = { ...defaultFilters };
      /** Присваивание вместо applyFilters для большей скорости */
      filteredNotifications.value = notifications.value;
    };

    const applyFilters = () => {
      filteredNotifications.value = notifications.value.filter((notification) => {
        const { dateFrom, dateTo, status, applicationNumber } = filters.value;
        let passes = true;
        if (dateFrom) {
          passes = passes && notification.createdAt >= dateFrom.toISOString();
        }
        if (dateTo) {
          passes = passes && notification.createdAt <= dateTo.toISOString();
        }
        if (status) {
          passes = passes && notification.status === status;
        }
        if (applicationNumber) {
          passes = passes && notification.applicationNumber === applicationNumber;
        }

        return passes;
      });
    };

    return {
      filters,
      notifications: filteredNotifications,
      applyFilters,
      resetFilters,
      statuses,
      loaded
    };
  },
};
</script>
