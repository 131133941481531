<template>
  <div class="main__grid">
    <div class="main__side">
      <div class="form-label">Филиалы</div>

      <div class="side-nav">
        <ul class="side-nav__list">
          <li class="side-nav__item" v-for="branch in branches" :key="branch.id">
            <details :open="isBranchSelected(branch)" class="more-item">
              <summary @click.prevent="selectBranch(branch)" class="more-item__top">
                <span class="more-item__marker"></span>
                <span class="more-item__title">{{ branch.name }}</span>
              </summary>
              <div class="more-item__main">
                <ul class="side-nav__list side-nav__list--sub">
                  <li
                    v-for="variant in accreditationVariants"
                    :key="variant"
                    class="side-nav__item"
                  >
                    <details
                      v-if="isVariantHasDropdown(variant)"
                      :open="isBranchVariantSelected(branch, variant)"
                      :class="{ 'is-disabled': !isVariantAllowed(variant) }"
                      class="more-item"
                    >
                      <summary
                        @click.prevent="handleVariantSelection(variant, branch)"
                        class="more-item__top"
                      >
                        <span class="more-item__marker"></span>
                        <span class="more-item__title">{{ variant }}</span>
                      </summary>
                      <ul
                        v-if="variant === $options.ACCREDITATION_VARIANTS.EDUCATION_LEVEL"
                        class="side-nav__list side-nav__list--sub"
                      >
                        <li
                          v-for="educationLevel in educationLevels"
                          :key="educationLevel.id"
                          class="side-nav__item"
                        >
                          <a
                            href="javascript:"
                            @click="selectEducationLevel(educationLevel)"
                            class="sub-link"
                            :class="{
                              'sub-link--active': isEducationLevelSelected(educationLevel),
                            }"
                          >
                            <span class="sub-link__label">{{ educationLevel.name }}</span>
                          </a>
                        </li>
                      </ul>
                      <ul
                        v-if="variant === $options.ACCREDITATION_VARIANTS.SPECIALITY"
                        class="side-nav__list side-nav__list--sub"
                      >
                        <li
                          v-for="speciality in specialities"
                          :key="speciality.id"
                          class="side-nav__item"
                        >
                          <a
                            href="javascript:"
                            @click="selectSpeciality(speciality)"
                            class="sub-link"
                            :class="{ 'sub-link--active': isSpecialitySelected(speciality) }"
                          >
                            <span class="sub-link__label"
                              >{{ speciality.code }} {{ speciality.name }}</span
                            >
                          </a>
                        </li>
                      </ul>
                      <ul
                        v-if="variant === $options.ACCREDITATION_VARIANTS.EGS"
                        class="side-nav__list side-nav__list--sub"
                      >
                        <li v-for="group in EGS" :key="group.id" class="side-nav__item">
                          <a
                            href="javascript:"
                            @click="selectEGS(group)"
                            class="sub-link"
                            :class="{ 'sub-link--active': isEGSSelected(group) }"
                          >
                            <span class="sub-link__label">{{ group.code }} {{ group.name }}</span>
                          </a>
                        </li>
                      </ul>
                      <ul
                        v-if="variant === $options.ACCREDITATION_VARIANTS.FIELD_OF_EDUCATION"
                        class="side-nav__list side-nav__list--sub"
                      >
                        <li
                          v-for="field in fieldsOfEducation"
                          :key="field.id"
                          class="side-nav__item"
                        >
                          <a
                            href="javascript:"
                            @click="selectFieldOfEducation(field)"
                            class="sub-link"
                            :class="{ 'sub-link--active': isFieldOfEducationSelected(field) }"
                          >
                            <span class="sub-link__label">{{ field.name }}</span>
                          </a>
                        </li>
                      </ul>
                    </details>
                    <a
                      v-else
                      href="javascript:"
                      @click="handleVariantSelection(variant, branch)"
                      class="sub-link"
                      :class="{
                        'sub-link--active': isBranchVariantSelected(branch, variant),
                        'is-disabled': !isVariantAllowed(variant),
                      }"
                    >
                      <span class="sub-link__label">{{ variant }}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </details>
          </li>
        </ul>
      </div>
    </div>

    <div class="main__body">
      <div v-if="licensedPrograms?.length" class="main__block">
        <div class="tbl-wrap">
          <table class="tbl">
            <thead>
              <tr>
                <th v-if="shouldShowActivityArea" colspan="2" class="align-center">ОПД</th>
                <th v-if="shouldShowActivityType" colspan="2" class="align-center">ВПД</th>
                <th rowspan="2" style="min-width: 100px">Код ОП</th>
                <th rowspan="2" style="min-width: 200px">Наименование основной ОП</th>
                <th rowspan="2" style="width: 75px">СУОС</th>
                <th rowspan="2" style="width: 75px">Гос. тайна</th>
                <th rowspan="2" style="width: 75px">Практика</th>
                <th colspan="3" class="align-center">Формы обучения</th>
                <th rowspan="2" style="width: 40px"></th>
              </tr>
              <tr>
                <th
                  v-if="shouldShowActivityArea || shouldShowActivityType"
                  style="font-size: 10px; min-width: 100px"
                >
                  Код
                </th>
                <th
                  v-if="shouldShowActivityArea || shouldShowActivityType"
                  style="font-size: 10px; min-width: 200px"
                >
                  Наименование
                </th>
                <th style="font-size: 10px; width: 100px">очная</th>
                <th style="font-size: 10px; width: 100px">очно-заочная</th>
                <th style="font-size: 10px; width: 100px">заочная</th>
              </tr>
            </thead>
            <template v-if="false">
              <tbody>
                <tr>
                  <td colspan="15" class="align-center">Не удалось найти доступные программы.</td>
                </tr>
              </tbody>
            </template>
            <template v-if="true">
              <tbody v-for="licensedProgram in licensedPrograms" :key="licensedProgram.id">
                <tr>
                  <td colspan="15" class="tbl-panel">
                    <strong>{{ licensedProgram.code }} {{ licensedProgram.name }}</strong>
                  </td>
                </tr>
                <tr
                  v-if="
                    readonly && !applicationProgramsByLicensedProgramId[licensedProgram.id]?.length
                  "
                >
                  <td colspan="15" class="align-center">Добавленные программы отсутствуют</td>
                </tr>
                <tr
                  v-for="applicationProgram in applicationProgramsByLicensedProgramId[
                    licensedProgram.id
                  ]"
                  :key="applicationProgram.Id"
                >
                  <td v-if="shouldShowActivityArea">
                    <InputText
                      v-model="applicationProgram.professional_activity_area_code"
                      :is-disabled="readonly"
                    />
                  </td>
                  <td v-if="shouldShowActivityArea">
                    <InputText
                      v-model="applicationProgram.professional_activity_area_name"
                      :is-disabled="readonly"
                    />
                  </td>
                  <td v-if="shouldShowActivityType">
                    <InputText
                      v-model="applicationProgram.professional_activity_type_code"
                      :is-disabled="readonly"
                    />
                  </td>
                  <td v-if="shouldShowActivityType">
                    <InputText
                      v-model="applicationProgram.professional_activity_type_name"
                      :is-disabled="readonly"
                    />
                  </td>
                  <td>
                    {{ applicationProgram.licensedProgram?.code }}
                  </td>
                  <td>
                    <InputText v-model="applicationProgram.name" :is-disabled="readonly" />
                  </td>
                  <td class="align-center">
                    <SwitchInput
                      type="checkbox"
                      size="s"
                      v-model="applicationProgram.ownStandard"
                      :disabled="readonly"
                    />
                  </td>
                  <td class="align-center">
                    <SwitchInput
                      type="checkbox"
                      size="s"
                      v-model="applicationProgram.gosSecret"
                      :disabled="readonly"
                    />
                  </td>
                  <td class="align-center">
                    <SwitchInput
                      type="checkbox"
                      size="s"
                      v-model="applicationProgram.practice"
                      :disabled="readonly"
                    />
                  </td>
                  <td>
                    <InputText
                      input-type="number"
                      v-model.number="applicationProgram.fullTimeCount"
                      :is-disabled="readonly"
                    />
                  </td>
                  <td>
                    <InputText
                      input-type="number"
                      v-model.number="applicationProgram.partTimeCount"
                      :is-disabled="readonly"
                    />
                  </td>
                  <td>
                    <InputText
                      input-type="number"
                      v-model.number="applicationProgram.extramuralCount"
                      :is-disabled="readonly"
                    />
                  </td>
                  <td class="align-center">
                    <button
                      v-if="!readonly"
                      @click="deleteApplicationProgram(applicationProgram)"
                      class="link link--gray"
                      type="button"
                    >
                      <SvgIcon name="delete" :size="16" />
                    </button>
                  </td>
                </tr>
                <tr v-if="!readonly">
                  <td colspan="15">
                    <CustomButton
                      label="Добавить ОП"
                      :type="4"
                      icon="add"
                      @click="addApplicationProgram(licensedProgram)"
                    />
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="main__footer main__footer--sticky">
    <div v-if="!readonly && licensedPrograms?.length" class="line line--jcsb">
      <div class="line__item"></div>
      <div class="line__item">
        <custom-button label="Сохранить" @click="saveChanges" />
      </div>
    </div>
  </div>
</template>

<script>
import { Applications, EducationalPrograms } from '@/repositories';
import CustomButton from '@/components/CustomButton';
import InputText from '@/components/InputText';
import SwitchInput from '@/components/SwitchInput';
import SvgIcon from '@/components/SvgIcon';
import { ApplicationBranchService } from '@/services/user/ApplicationBranchService';
import { groupBy } from '@/utils/helpers';

/*
 * TODO Add loaders for each of accreditation variants when options are still loading.
 * TODO Add loader for a table when licensed programs are loading.
 * TODO Add empty option for a table and each of accreditation variants when there is no resources in response.
 */
export default {
  name: 'EducationalPrograms',
  props: {
    editable: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
  },
  components: { SvgIcon, SwitchInput, InputText, CustomButton },
  async created() {
    const [branches, programs] = await Promise.all([
      Applications.getBranches(this.id),
      Applications.getPrograms(this.id),
    ]);
    this.branches = branches;
    this.applicationPrograms = programs;

    if (this.branches.length) {
      this.selectBranch(this.branches[0]);
    }
  },
  watch: {
    async id(id) {
      this.branches = await Applications.getBranches(id);
      if (this.branches.length) {
        this.selectBranch(this.branches[0]);
      }
    },
    async selectedEducationLevel(educationLevel) {
      this.clearLicensedPrograms();

      if (this.selectedBranch == null || educationLevel == null) {
        return;
      }

      this.licensedPrograms = await ApplicationBranchService.getLicensedPrograms(
        this.id,
        this.selectedBranch.id,
        { education_level_id: educationLevel.id }
      );
    },
    async selectedSpeciality(speciality) {
      this.clearLicensedPrograms();

      if (this.selectedBranch == null || speciality == null) {
        return;
      }

      this.licensedPrograms = await ApplicationBranchService.getLicensedPrograms(
        this.id,
        this.selectedBranch.id,
        { speciality_id: speciality.id }
      );
    },
    async selectedEGS(group) {
      this.clearLicensedPrograms();

      if (this.selectedBranch == null || group == null) {
        return;
      }

      this.licensedPrograms = await ApplicationBranchService.getLicensedPrograms(
        this.id,
        this.selectedBranch.id,
        { egs_id: group.id }
      );
    },
    async selectedFieldOfEducation(field) {
      this.clearLicensedPrograms();

      if (this.selectedBranch == null || field == null) {
        return;
      }

      this.licensedPrograms = await ApplicationBranchService.getLicensedPrograms(
        this.id,
        this.selectedBranch.id,
        { field_of_education_id: field.id }
      );
    },
  },
  ACCREDITATION_VARIANTS: {
    EDUCATION_LEVEL: 'Уровень образования',
    SPECIALITY: 'Направление подготовки',
    EGS: 'УГСН',
    FIELD_OF_EDUCATION: 'Область образования',
    PROFESSIONAL_ACTIVITY_AREA: 'ОПД',
    PROFESSIONAL_ACTIVITY_TYPE: 'ВПД',
  },
  data() {
    return {
      branches: [],
      selectedBranch: null,

      accreditationVariants: [
        'Уровень образования',
        'Направление подготовки',
        'УГСН',
        'Область образования',
        'ОПД',
        'ВПД',
      ],
      selectedVariant: null,

      educationLevels: [],
      selectedEducationLevel: null,

      specialities: [],
      selectedSpeciality: null,

      EGS: [],
      selectedEGS: [],

      fieldsOfEducation: [],
      selectedFieldOfEducation: null,

      licensedPrograms: [],
      applicationPrograms: [],
    };
  },
  computed: {
    shouldShowActivityArea() {
      return this.isVariantSelected(
        this.$options.ACCREDITATION_VARIANTS.PROFESSIONAL_ACTIVITY_AREA
      );
    },
    shouldShowActivityType() {
      return this.isVariantSelected(
        this.$options.ACCREDITATION_VARIANTS.PROFESSIONAL_ACTIVITY_TYPE
      );
    },
    applicationProgramsByLicensedProgramId() {
      if (!this.applicationPrograms?.length) {
        return {};
      }

      const variant = this.accreditationVariants.indexOf(this.selectedVariant) + 1;
      const programs = this.applicationPrograms.filter(
        (program) => program.accreditation_variant === variant
      );
      return groupBy(programs, 'licensedProgram.id');
    },
    allowedVariants() {
      if (!this.applicationPrograms?.length) {
        return this.accreditationVariants;
      }

      const index = this.applicationPrograms[0].accreditation_variant - 1;
      return this.accreditationVariants[index] ? [this.accreditationVariants[index]] : [];
    },
    readonly() {
      return !this.editable.editable;
    },
  },
  methods: {
    selectBranch(branch) {
      this.handleVariantSelection(this.allowedVariants[0], branch);
    },
    selectBranchVariant(branch, variant) {
      if (!this.isVariantAllowed(variant)) {
        return;
      }

      this.selectedVariant = variant;
      this.selectedBranch = branch;
    },
    isVariantAllowed(variant) {
      return this.allowedVariants.includes(variant);
    },
    isBranchSelected(branch) {
      return this.selectedBranch === branch;
    },
    isVariantSelected(variant) {
      return this.selectedVariant && this.selectedVariant === variant;
    },
    isBranchVariantSelected(branch, variant) {
      return this.isBranchSelected(branch) && this.isVariantSelected(variant);
    },
    handleVariantSelection(variant, branch) {
      if (!this.isVariantAllowed(variant)) {
        window.alert(
          'Невозможно выбрать данный вариант аккредитации. Сначала необходимо удалить все добавленные программы по другим вариантам аккредитации.'
        );
        return;
      }
      this.clearLicensedPrograms();
      this.selectBranchVariant(branch, variant);

      const VARIANTS = this.$options.ACCREDITATION_VARIANTS;

      if (variant === VARIANTS.EDUCATION_LEVEL) {
        this.clearSelectedEducationLevel();
        this.refetchEducationLevels(branch);
      }

      if (variant === VARIANTS.SPECIALITY) {
        this.clearSelectedSpeciality();
        this.refetchSpecialities(branch);
      }

      if (variant === VARIANTS.EGS) {
        this.clearSelectedEGS();
        this.refetchEGS(branch);
      }

      if (variant === VARIANTS.FIELD_OF_EDUCATION) {
        this.clearSelectedFieldOfEducation();
        this.refetchFieldsOfEducation(branch);
      }

      if (
        variant === VARIANTS.PROFESSIONAL_ACTIVITY_TYPE ||
        variant === VARIANTS.PROFESSIONAL_ACTIVITY_AREA
      ) {
        ApplicationBranchService.getLicensedPrograms(this.id, this.selectedBranch.id).then(
          (programs) => (this.licensedPrograms = programs)
        );
      }
    },
    isVariantHasDropdown(variant) {
      return ![
        this.$options.ACCREDITATION_VARIANTS.PROFESSIONAL_ACTIVITY_AREA,
        this.$options.ACCREDITATION_VARIANTS.PROFESSIONAL_ACTIVITY_TYPE,
      ].includes(variant);
    },

    clearLicensedPrograms() {
      this.licensedPrograms = [];
    },

    selectEducationLevel(educationLevel) {
      this.selectedEducationLevel = educationLevel;
    },
    isEducationLevelSelected(educationLevel) {
      return this.selectedEducationLevel === educationLevel;
    },
    clearSelectedEducationLevel() {
      this.selectedEducationLevel = null;
    },
    async refetchEducationLevels(branch) {
      this.educationLevels = [];
      this.educationLevels = await ApplicationBranchService.getEducationLevels(this.id, branch.id);
    },
    selectSpeciality(speciality) {
      this.selectedSpeciality = speciality;
    },
    isSpecialitySelected(speciality) {
      return this.selectedSpeciality && this.selectedSpeciality === speciality;
    },
    clearSelectedSpeciality() {
      this.selectedSpeciality = null;
    },
    async refetchSpecialities(branch) {
      this.specialities = [];
      this.specialities = await ApplicationBranchService.getSpecialities(this.id, branch.id);
    },
    selectEGS(egs) {
      this.selectedEGS = egs;
    },
    isEGSSelected(egs) {
      return this.selectedEGS && this.selectedEGS === egs;
    },
    clearSelectedEGS() {
      this.selectedEGS = null;
    },
    async refetchEGS(branch) {
      this.EGS = [];
      this.EGS = await ApplicationBranchService.getEnlargedSpecialityGroups(this.id, branch.id);
    },
    selectFieldOfEducation(field) {
      this.selectedFieldOfEducation = field;
    },
    isFieldOfEducationSelected(field) {
      return this.selectedFieldOfEducation && this.selectedFieldOfEducation === field;
    },
    clearSelectedFieldOfEducation() {
      this.selectedFieldOfEducation = null;
    },
    async refetchFieldsOfEducation(branch) {
      this.fieldsOfEducation = [];
      this.fieldsOfEducation = await ApplicationBranchService.getFieldsOfEducation(
        this.id,
        branch.id
      );
    },
    async addApplicationProgram(licensedProgram) {
      const program = {
        licensed_program_id: licensedProgram.id,
        accreditation_variant: this.accreditationVariants.indexOf(this.selectedVariant) + 1,
      };

      const createdPrograms = await EducationalPrograms.add(this.id, this.selectedBranch.id, [
        program,
      ]);
      this.applicationPrograms.push(createdPrograms[0]);
    },
    async deleteApplicationProgram(program) {
      if (
        !window.confirm(
          'Вы уверены, что хотите удалить программу? Все несохраненные изменения будут потеряны.'
        )
      ) {
        return;
      }

      await EducationalPrograms.removeProgram(program.id);
      this.applicationPrograms = await Applications.getPrograms(this.id);
    },
    async saveChanges() {
      const isNothingToSave = !this.applicationPrograms?.length;
      if (isNothingToSave) {
        return;
      }

      try {
        await EducationalPrograms.update(this.id, this.applicationPrograms);
      } catch {
        window.alert('Не удалось сохранить изменения в программах. Попробуйте, пожалуйста, позже!');
      }
    },
  },
};
</script>
