<template>
  <div class="wrap wrap--side" :class="{ collapsed }">
    <navigation-sidebar :links="links" />
    <router-view />
  </div>
</template>

<script>
import NavigationSidebar from '@/components/NavigationSidebar';
import { Applications } from '@/repositories';
export default {
  name: 'AccreditationsLayout',
  components: { NavigationSidebar },
  created() {
    Applications.getCounts().then((counts) => (this.applicationsCounts = counts));
    Applications.getNotificationsCount().then(count => this.notificationsCount = count);
  },
  data() {
    return {
      applicationsCounts: {
        new: null,
        sent: null,
        requireFix: null,
        requests: null,
      },
      notificationsCount: null,
    };
  },
  computed: {
    links() {
      return [
        {
          label: 'Новые заявления',
          to: 'user.applications.new',
          icon: 'new-statement',
          qty: this.applicationsCounts.new,
        },    
        {
          label: 'Отправленные заявления',
          to: 'user.applications.sent',
          icon: 'send-statement',
          qty: this.applicationsCounts.sent,
        },
        {
          label: 'Требующие исправления',
          to: 'user.applications.require-fix',
          icon: 'for-edit',
          qty: this.applicationsCounts.requireFix,
        },
        {
          label: 'Уведомления',
          to: 'user.applications.notifications',
          icon: 'notifications',
          qty: this.notificationsCount,
        },
        {
          label: 'Запросы',
          to: 'user.applications.requests',
          icon: 'requests',
          qty: this.applicationsCounts.requests,
        }
      ];
    },
    collapsed() {
      return this.$store.state.sidebar.collapsed;
    },
  },
};
</script>
