import axios from '@/api/axios';

export const EducationalPrograms = {
  async getEgs(applicationId, branchId, programTypeId) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/branches/${branchId}/program-types/${programTypeId}/egs`
    );
    return response.data.data;
  },
  async removeProgram(programId) {
    await axios.delete(
      `user/organization/applications/programs/${programId}`
    );
  },
  async getLicensedPrograms(application, branch, programType, specialty) {
    const response = await axios.get(
      `user/organization/applications/${application}/branches/${branch}/licensed-programs?specialty=${specialty}&type=${programType}`
    );
    return response.data.data;
  },
  /**
   * @return {Promise<ApplicationProgram[]>}
   */
  async add(application, branch, programs) {
    const response = await axios.post(
      `user/organization/applications/${application}/branches/${branch}/programs`,
      { programs },
    );

    return response.data.data;
  },
  /**
   * @param {string} application
   * @param {string} branch
   * @returns {Promise<void>}
   */
  async addAll(application, branch, ) {
    await axios.post(
        `user/organization/applications/${application}/branches/${branch}/programs/add-all`
    );
  },
  async update(application, programs) {
    await axios.put(
      `user/organization/applications/${application}/programs`,
      { programs }
    );
  },
}

/**
 * @typedef EnlargedGroupSpecialty
 * @property {string} id
 * @property {string} name
 * @property {string} code
 * @property {ApplicationProgram[]} applicationPrograms
 */

/**
 * @typedef ApplicationProgram
 */