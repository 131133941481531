<template>
  <main class="main">
    <div class="main__wrap">
      <div class="main__body">
        <div class="main__block">
          <applications-filters
            v-model="filters"
            :types="types"
            :reasons="reasons"
            @filters:apply="applyFilters"
            @filters:reset="resetFilters"
            date-label="Дата регистрации"
          />
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th v-for="column in $options.columns" :key="column">{{ column }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="loaded && !applications.length">
                      <td :colspan="$options.columns.length" class="align-center">Заявления не найдены</td>
                    </tr>
                    <template v-else>
                      <tr
                        v-for="(application, index) in applications"
                        :key="application.id"
                        class="tbl-link"
                        @click="redirectToApplication(application.id)"
                      >
                        <td>{{ index + 1 }}</td>
                        <td>
                          {{
                            application.registeredAt &&
                            this.$filters.formatDate(application.registeredAt)
                          }}
                        </td>
                        <td>{{ application.registrationNumber }}</td>
                        <td>{{ application.type }}</td>
                        <td>{{ application.reasons.map((reason) => reason.name).join('; ') }}</td>
                        <td>
                          {{ application.branches.map((b) => b.name || b.shortName).join('; ') }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref } from 'vue';
import ApplicationsFilters from '@/components/blocks/ApplicationsFilters';
import { Applications } from '@/repositories';
import { getApplicationsTypes } from '@/composables/getApplicationsTypes';
import { getApplicationsReasons } from '@/composables/getApplicationsReasons';
import { useApplicationFilters } from '@/composables/useApplicationFilters';

export default {
  name: 'FixApplications',
  components: { ApplicationsFilters },
  columns: [
    '№',
    'Дата регистрации',
    'Номер заявления',
    'Тип заявления',
    'Основание заявления',
    'Филиалы',
  ],
  setup() {
    const applications = ref([]);
    /* Костыль для отображения информации об отсутствии данных после их скачивания */
    const loaded = ref(false);
    Applications.getRequiringFix().then((apps) => {
      applications.value = apps;
      loaded.value = true;
    });

    const types = getApplicationsTypes(applications);
    const reasons = getApplicationsReasons(applications);

    const { filters, filteredApplications, applyFilters, resetFilters } =
      useApplicationFilters(applications, {dateKeyToFilterBy: 'registeredAt'});

    return {
      filters,
      applications: filteredApplications,
      applyFilters,
      resetFilters,
      types,
      reasons,
      loaded,
    };
  },
  methods: {
    redirectToApplication(id) {
      this.$router.push({ name: 'user.application.show', params: { id } });
    },
  },
};
</script>
