<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--full">
              <text-area
                label="Ошибочно указанные данные"
                placeholder="Укажите данные, в которых допущены ошибки"
                :rows="3"
                v-model="form.incorrect_info"
                :has-error="vuelidate.form.incorrect_info.errors"
                :vuelidate-errors="vuelidate.form.incorrect_info.errors"
                :is-disabled="!editable.editable"
              />
            </div>
            <div class="col col--full">
              <text-area
                label="Корректные данные"
                placeholder="Укажите корректные данные"
                :rows="3"
                v-model="form.correct_info"
                :has-error="vuelidate.form.correct_info.errors"
                :vuelidate-errors="vuelidate.form.correct_info.errors"
                :is-disabled="!editable.editable"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main__footer main__footer--sticky">
    <div class="line">
      <div class="line__item line__item--mra"></div>
      <div class="line__item">
        <custom-button v-if="editable.editable" label="Сохранить" @click="save" />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import TextArea from '@/components/TextArea';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import { Applications } from '@/repositories';

export default {
  name: 'ApplicationCorrection',
  props: {
    id: {
      type: String,
      required: true,
    },
    editable: Object,
    application: Object,
  },
  components: {
    TextArea,
    CustomButton,
  },
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        incorrect_info: this.application?.incorrect_info ?? null,
        correct_info: this.application?.correct_info ?? null,
      },
    };
  },
  validations() {
    return {
      form: {
        incorrect_info: { required },
        correct_info: { required },
      },
    };
  },
  watch: {
    application() {
      this.form.incorrect_info = this.application?.incorrect_info ?? null;
      this.form.correct_info = this.application?.correct_info ?? null;
    },
  },
  methods: {
    save() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      Applications
        .updateCorrections(this.application.id, this.form)
        .then(() => Object.assign(this.application, this.form))
        .catch(() =>
          window.alert('Не удалось сохранить изменения. Попробуйте, пожалуйста, позже!')
        );
    },
  },
};
</script>
