<template>
  <main class="main">
    <div class="main__wrap main__wrap--single">
      <div class="main__header">
        <div class="main__title">
          <div class="title">Заявление о государственной аккредитации</div>
        </div>
        <div class="main__tabs">
          <nav-tabs :links="$options.links" />
        </div>
      </div>

      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--full">
                  <custom-select
                    label="Тип заявления"
                    placeholder="Выберите"
                    :options="typeOptions"
                    v-model="selectedType"
                  />
                </div>
                <div class="col col--full">
                  <div class="form-label">Основания заявления</div>
                  <div class="form-group">
                    <switch-input
                      v-for="reason in reasons"
                      :key="reason.id"
                      :value="reason.id"
                      type="checkbox"
                      size="m"
                      :label="reason.name"
                      v-model="form.reasons"
                      :disabled="selectedReasonsGroup && reason.group !== selectedReasonsGroup"
                    />
                  </div>
                  <div v-if="vuelidate.form.reasons.$error" class="error-text">
                    {{ vuelidate.form.reasons.$errors[0].$message }}
                  </div>
                </div>
                <div class="col col--half">
                  <custom-select
                    label="Предыдущее свидетельство"
                    :options="certificateOptions"
                    placeholder="Выберите свидетельство"
                    v-model="form.certificate"
                    @change="vuelidate.form.certificate.$touch"
                    :has-error="vuelidate.form.certificate.$error"
                    :vuelidate-errors="vuelidate.form.certificate.$errors"
                  />
                </div>
                <div class="col col--half">
                  <custom-select
                    label="Действующая лицензия"
                    placeholder="Выберите лицензию"
                    :options="licenseOptions"
                    v-model="form.license"
                    @change="vuelidate.form.license.$touch"
                    :has-error="vuelidate.form.license.$error"
                    :vuelidate-errors="vuelidate.form.license.$errors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="main__block">
          <div class="block">
            <div class="block__top">
              <div class="block-title">Информирование о ходе процедуры</div>
            </div>

            <div class="block__main">
              <div class="row row--aife">
                <div class="col col--half">
                  <input-text
                    label="Уполномоченное лицо"
                    placeholder="Иванов Александр Петрович"
                    v-model="form.contact.name"
                    @change="vuelidate.form.contact.name.$touch"
                    :has-error="vuelidate.form.contact.name.$error"
                    :vuelidate-errors="vuelidate.form.contact.name.$errors"
                  />
                </div>
                <div class="col col--fourth">
                  <input-text
                    label="Телефон"
                    placeholder="(000) 000 00 00"
                    input-type="tel"
                    prefix="+7"
                    v-model="form.contact.phoneNumber"
                    @change="vuelidate.form.contact.phoneNumber.$touch"
                    :has-error="vuelidate.form.contact.phoneNumber.$error"
                    :vuelidate-errors="vuelidate.form.contact.phoneNumber.$errors"
                  />
                </div>
                <div class="col col--fourth">
                  <input-text
                    label="E-mail"
                    placeholder="example@mail.com"
                    input-type="email"
                    v-model="form.contact.email"
                    @change="vuelidate.form.contact.email.$touch"
                    :has-error="vuelidate.form.contact.email.$error"
                    :vuelidate-errors="vuelidate.form.contact.email.$errors"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main__footer main__footer--sticky">
        <div class="line line--jcsb">
          <div class="line__item"></div>
          <div class="line__item">
            <custom-button label="Сохранить" @click="submit" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import CustomSelect from '@/components/CustomSelect';
import CustomButton from '@/components/CustomButton';
import InputText from '@/components/InputText';
import SwitchInput from '@/components/SwitchInput';
import NavTabs from '@/components/NavTabs';
import useVuelidate from '@vuelidate/core';
import { Applications, ApplicationTypes, educationalOrganization } from '@/repositories';
import { email, minLength, required } from '@/utils/validators';
export default {
  name: 'CreateApplication',
  components: { CustomButton, CustomSelect, InputText, SwitchInput, NavTabs },
  links: [
    { label: 'Свойства заявления', to: { name: 'user.application.create' } },
    { label: 'Филиалы', disabled: true },
    { label: 'Образовательные программы', disabled: true },
    { label: 'Документы', disabled: true },
  ],
  setup() {
    return { vuelidate: useVuelidate() };
  },
  created() {
    ApplicationTypes.get().then((types) => (this.types = types));
    educationalOrganization
      .getLicenses()
      .then((licenses) => (this.licenses = licenses))
      /* Устанавливаем первую действующую лицензию выбранной */
      .then(() => this.form.license = this.licenses.find(license => license.status?.valid)?.id);
    educationalOrganization
      .getCertificates()
      .then((certificates) => (this.certificates = certificates))
      /* Устанавливаем первый действующий сертификат выбранным */
      .then(() => this.form.certificate = this.certificates.find(certificate => certificate.status?.code === 'InForce')?.id);
  },
  data() {
    return {
      types: [{ id: '01210', name: 'Тип' }],
      selectedType: null,
      reasons: [],
      licenses: [],
      certificates: [],
      form: this.getDefaultForm(),
    };
  },
  validations() {
    return {
      form: {
        reasons: { required },
        license: { required },
        /* Add requiredIf if user will be able to deselect certificate */
        certificate: {},
        contact: {
          name: { required },
          phoneNumber: { required, minLength: minLength(10) }, // TODO добавить маску
          email: { required, email },
        },
      },
    };
  },
  computed: {
    typeOptions() {
      return this.types.map((type) => ({ value: type.id, text: type.name_2023 ?? type.name }));
    },
    licenseOptions() {
      return this.licenses.map((license) => {
        const date = license.orderDate && this.$filters.formatDate(license.orderDate);
        const valid = license.status.valid ? '(Действует)' : '(Не действует)';
        const regNumber = license.registrationNumber || '';
        return {
          value: license.id,
          text: `№ ${regNumber} от ${date} ${valid}`,
        };
      });
    },
    certificateOptions() {
      return this.certificates.map((certificate) => {
        const regNumber = certificate.registrationNumber || '';
        const date = certificate.issuedAt && this.$filters.formatDate(certificate.issuedAt);
        return {
          value: certificate.id,
          text: `№ ${regNumber} от ${date} (${certificate.status?.name})`,
        };
      });
    },
    selectedReasonsGroup() {
      if (this.form.reasons.length === 0) {
        return null;
      }
      const selectedReason = this.reasons.find((reason) => this.form.reasons.includes(reason.id));
      return selectedReason ? selectedReason.group : null;
    },
  },
  watch: {
    selectedType(selectedType) {
      if (!selectedType) {
        return;
      }
      ApplicationTypes.getReasons(selectedType).then((reasons) => (this.reasons = reasons));

      /** Сброс выбранных причин, чтобы нельзя было выбрать из нескольких типов */
      this.form.reasons = [];
    },
  },
  methods: {
    submit() {
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      Applications.create(this.form)
        .then(this.resetForm)
        .then(() => this.$router.push({ name: 'user.applications.new' }));
    },
    resetForm() {
      this.form = this.getDefaultForm();
    },
    getDefaultForm() {
      return {
        reasons: [],
        license: null,
        certificate: null,
        contact: {
          name: null,
          phoneNumber: null,
          email: null,
        },
      };
    },
  },
};
</script>