<template>
  <div class="wrap">
    <main class="main">
      <div class="main__wrap main__wrap--single">
        <div class="main__header">
          <div class="main__title">
            <div class="title">
              Заявление о государственной аккредитации
              <span v-if="application.registrationNumber"> № {{application.registrationNumber}}</span>
            </div>
          </div>
        </div>
        <div class="main__tabs">
          <nav-tabs :links="tabs" />
        </div>

        <router-view
          :application="application"
          :editable="{ editable: application.editable } /* Костыль, никто не дал ответа, почему примитив не прокидывается */"
        />
      </div>
    </main>
  </div>
</template>

<script>
import NavTabs from '@/components/NavTabs';
import { Applications } from '@/repositories';
export default {
  name: 'ApplicationLayout',
  components: { NavTabs },
  created() {
    this.getApplication();
  },
  watch: {
    id() {
      this.getApplication();
    },
  },
  data() {
    return {
      application: {},
    };
  },
  computed: {
    tabs() {
      const CORRECTION_REASON_ID = 'e24d77be-b35d-4ed1-880e-fa524eb4e233';
      const tabs = [
        { label: 'Свойства заявления', to: { name: 'user.application.show' } },
        { label: 'Филиалы', to: { name: 'user.application.show.branches' } },
        { label: 'Образовательные программы', to: { name: 'user.application.show.programs' } },
        { label: 'Изменение', to: { name: 'user.application.show.correction' }, reasons: [CORRECTION_REASON_ID] },
        { label: 'Документы', to: { name: 'user.applications.show.documents' } },
      ];

      return tabs.filter(tab => {
        if (tab.reasons?.length) {
          return this.application?.reasons?.some(reason => tab.reasons.includes(reason.id));
        }

        return true;
      })
          .map(({label, to}) => ({label, to}));
    },
  },
  methods: {
    getApplication() {
      Applications.getById(this.id).then((application) => (this.application = application));
    },
  },
  props: {
    id: String,
  },
};
</script>