import axios from '@/api/axios';

export const ApplicationTypes = {
  /** @return {Promise<ApplicationType[]>} */
  async get() {
    const response = await axios.get('application-types');
    return response.data.data;
  },
  /** @return {Promise<ApplicationReason[]>}*/
  async getReasons(typeId) {
    const response = await axios.get(`application-types/${typeId}/reasons`);
    return response.data.data;
  }
}

/**
 * @typedef ApplicationType
 * @property {string} id
 * @property {string|null} name
 * @property {string|null} name_2023
 */

/**
 * @typedef ApplicationReason
 * @property {string} id
 * @property {string|null} name
 * @property {number} group
 */