import axios from '@/api/axios';

export const ApplicationBranchService = {
  async getEducationLevels(applicationId, branchId) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/branches/${branchId}/education-levels`
    );

    return response.data.data;
  },
  async getLicensedPrograms(applicationId, branchId, params = {}) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/branches/${branchId}/licensed-programs`,
      { params }
    );
    return response.data.data;
  },
  async getSpecialities(applicationId, branchId) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/branches/${branchId}/specialities`
    );

    return response.data.data;
  },
  async getEnlargedSpecialityGroups(applicationId, branchId) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/branches/${branchId}/egs`
    );

    return response.data.data;
  },
  async getFieldsOfEducation(applicationId, branchId) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/branches/${branchId}/fields-of-education`
    );

    return response.data.data;
  },
};
