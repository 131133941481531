import { computed } from 'vue';

export function getApplicationsTypes(applications) {
  return computed(() => {
    const types = applications.value.map(application => application.type);
    return unique(types);
  });
}

function unique(array) {
  return [...new Set(array)];
}