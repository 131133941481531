<template>
  <div class="block">
    <div class="block__main">
      <div class="row row--aife">
        <div class="col col--half">
          <input-text
            :label="`${dateLabel} от`"
            is-date
            button="calendar"
            v-model="filters.dateFrom"
          />
        </div>
        <div class="col col--half">
          <input-text label="до" is-date button="calendar" v-model="filters.dateTo" />
        </div>
        <div class="col col--half">
          <custom-select
            label="Тип процедуры"
            placeholder="Все типы процедур"
            :options="typesOptions"
            v-model="filters.type"
          />
        </div>
        <div class="col col--half">
          <custom-select
            label="Основание"
            placeholder="Все основания"
            :options="reasonsOptions"
            v-model="filters.reason"
          />
        </div>
        <div class="col col--full">
          <div class="line line--jcfe">
            <div class="line__item">
              <custom-button :type="4" label="Сбросить" @click="$emit('filters:reset')" />
            </div>
            <div class="line__item">
              <custom-button label="Применить" @click="$emit('filters:apply')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
import CustomButton from '@/components/CustomButton';
import CustomSelect from '@/components/CustomSelect';
export default {
  name: 'ApplicationsFilters',
  components: { InputText, CustomButton, CustomSelect },
  computed: {
    typesOptions() {
      return this.types.filter((type) => type != null).map((type) => ({ value: type }));
    },
    reasonsOptions() {
      return this.reasons.map((reason) => ({ value: reason.id, text: reason.name }));
    },
    filters: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
  props: {
    modelValue: Object,
    types: Array,
    reasons: Array,
    dateLabel: {
      type: String,
      default: 'Дата создания',
    },
  },
  emits: ['filters:reset', 'filters:apply'],
};
</script>