import axios from '@/api/axios';
import { ValidationError } from '@/exceptions';

export const Applications = {
  /** @return {Promise<Application[]>} */
  async getNew() {
    const response = await axios.get('user/organization/applications?scope=new');
    return response.data.data;
  },
  /** @return {Promise<Application[]>} */
  async getSent() {
    const response = await axios.get('user/organization/applications?scope=sent');
    return response.data.data;
  },
  /** @return {Promise<Application[]>} */
  async getRequiringFix() {
    const response = await axios.get('user/organization/applications?scope=requiresFix');
    return response.data.data;
  },
  /** @return {Promise<ApplicationsCounts>} */
  async getCounts() {
    const response = await axios.get('user/organization/applications/count');
    return response.data;
  },
  /** @return {Promise<ApplicationNotification[]>} */
  async getNotifications() {
    const response = await axios.get('user/organization/applications/notifications');
    return response.data.data;
  },
  /** @return */
  async getNotificationsCount() {
    const response = await axios.get('user/organization/applications/notifications/count');
    return response.data.notifications;
  },
  async create(application) {
    await axios.post('user/organization/applications/create', application);
  },
  async remove(applicationId) {
    await axios.delete(
      `user/organization/applications/${applicationId}`
    );
  },
  async getById(id) {
    const response = await axios.get(`user/organization/applications/${id}`);
    return response.data.application;
  },
  /**
   * @param {string} applicationId
   * @return {Promise<DocumentType[]>}
   */
  async getDocumentTypes(applicationId) {
    const response = await axios.get(`user/organization/applications/${applicationId}/document-types`);
    return response.data.data;
  },
  /**
   * @param {string} applicationId
   * @return {Promise<ApplicationFile[]>}
   */
  async getFiles(applicationId) {
    const response = await axios.get(`user/organization/applications/${applicationId}/files`);
    return response.data.data;
  },
  async secretLicense(applicationId) {
    const response = await axios.get(
        `user/organization/applications/${applicationId}/secret-license`
    );
    return response.data.data;
  },
  async updateSecretLicense(applicationId, license) {
    await axios.put(
        `user/organization/applications/${applicationId}/secret-license`,
        license
    );
  },
  /**
   * @param {string} applicationId
   * @return {Promise<void>}
   */
  async getBranchesWithProgramTypes(applicationId) {
    const response = await axios.get(`user/organization/applications/${applicationId}/branches/program-types`);
    return response.data.data;
  },
  /**
   * @param {string} applicationId
   * @param {File[]} files
   * @param {string} documentTypeId
   * @return {Promise<void>}
   */
  async addFiles(applicationId, files, documentTypeId) {
    const form = new FormData;
    files.forEach(file => form.append('files[]', file));
    form.append('documentType', documentTypeId);
    try {
      await axios.post(`user/organization/applications/${applicationId}/save-files`, form);
    } catch (error) {
      if (error.response?.status !== 422) {
        throw error;
      }
      throw new ValidationError(error.response.data.errors);
    }
  },
  /**
   * @param {string} applicationId
   * @param {File} file
   * @return {Promise<void>}
   */
  async addCopy(applicationId, file) {
    const payload = new FormData;
    payload.append('file', file);
    try {
      await axios.post(`user/organization/applications/${applicationId}/copy`, payload);
    } catch (error) {
      if (error.response?.status !== 422) {
        throw error;
      }
      throw new ValidationError(error.response.data.errors);
    }
  },
  /**
   * @param {string} application
   * @return {Promise<ApplicationFile>}
   */
  async getCopy(application) {
    const response = await axios.get(
      `user/organization/applications/${application}/copy`
    );
    return response.data.data;
  },
  /**
   * @param {string} application
   * @return {Promise<ApplicationFile[]>}
   */
  async getCopies(application) {
    const response = await axios.get(
      `user/organization/applications/${application}/copies`
    );
    return response.data.data;
  },
  /**
   *
   * @param {string} application
   * @return {Promise<File>}
   */
  async getCopyTemplate(application) {
    const response = await axios.get(
      `user/organization/applications/${application}/copy-template`,
      { responseType: 'blob' }
    );
    return new File([response.data], 'Заявление.docx');
  },
  /**
   * @param {string} application
   * @param {string} copy
   * @return {Promise<void>}
   */
  async removeCopy(application, copy) {
    await axios.delete(
      `user/organization/applications/${application}/copy/${copy}`
    );
  },
  /**
   * @param {string} fileId
   * @return {Promise<void>}
   */
  async removeFile(fileId) {
    await axios.delete(`user/organization/application-files/${fileId}/delete`);
  },

  async requestList(fileId) {
    await axios.delete(`user/organization/application-files/${fileId}/delete`);
  },
  /**
   * @param {string} application
   * @return {Promise<void>}
   */
  async send(application) {
    await axios.patch(
      `user/organization/applications/${application}/send`
    );
  },
  /**
   * @param {string} applicationId
   * @return {Promise<Branch[]>}
   */
  async getLicenseBranches(applicationId) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/license/branches`
    );
    return response.data.data;
  },
  /**
   * @param {string} applicationId
   * @return {Promise<Branch[]>}
   */
  async getBranches(applicationId) {
    const response = await axios.get(
      `user/organization/applications/${applicationId}/branches`
    );
    return response.data.data;
  },
  /**
   * @param {string} applicationId
   * @param {string[]} branches
   * @return {Promise<void>}
   */
  async updateBranches(applicationId, branches) {
    await axios.put(
      `user/organization/applications/${applicationId}/branches`,
      { branches }
    );
  },

  /**
   *
   * @param {string} applicationId
   * @param {Object} corrections
   * @param {string} corrections.incorrect_info
   * @param {string} corrections.correct_info
   */
   async updateCorrections(applicationId, corrections) {
    await axios.put(`user/organization/applications/${applicationId}/corrections`, corrections);
    },
    async storeDocumentLink(applicationId, documentTypeId, url) {
       await axios.put(
           `user/organization/applications/${applicationId}/links`,
           {documentType: documentTypeId, url}
       )
    },
    async removeLinkByType(applicationId, documentTypeId) {
      await axios.delete(
          `user/organization/applications/${applicationId}/link`,
          {data: {documentType: documentTypeId}}
      )
    },
  async getPrograms(applicationId) {
     const response = await axios.get(
         `user/organization/applications/${applicationId}/programs`
     );

     return response.data.data;
  },
};

/**
 * @typedef Application
 * @property {string} id
 * @property {string|null} createdAt
 * @property {string|null} registrationNumber
 * @property {string|null} registeredAt
 * @property {string|null} type
 * @property {string|null} status
 * @property {object} reasons
 * @property {string} reasons.id
 * @property {string|null} reasons.name
 * @property {object} branches
 * @property {string} branches.id
 * @property {string|null} branches.name
 * @property {string|null} branches.shortName
 */

/**
 * @typedef ApplicationsCounts
 * @property {number} new
 * @property {number} sent
 * @property {number} requireFix
 */


/**
 * @typedef ApplicationNotification
 * @property {string} id
 * @property {string|null} applicationNumber
 * @property {string|null} status
 * @property {string|null} file
 * @property {string|null} number
 * @property {string|null} createdAt
 * @property {string|null} signedAt
 * @property {string|null} sentAt
 */


/**
 * @typedef DocumentType
 * @property {string} id
 * @property {string} name
 * @property {boolean} [required]
 */


/**
 * @typedef ApplicationFile
 * @property {string} id
 * @property {string} name
 * @property {string} path
 * @property {DocumentType} type
 */


/**
 * @typedef DownloadedFile
 */


/**
 * @typedef Branch
 * @property {string} id
 * @property {string} name
 * @property {string|null} legalAddress
 */