/**
 * Собирает из многомерного массива или массива объектов значения переданного ключа.
 * Если для указанного ключа нет значения, то оно пропускается.
 * @param {object[]|Array.<[]>|string[]} array
 * @param {string|number} key
 * @return {[]} - Массив с значениями переданного ключа.
 */
export function pluck(array, key) {
  // noinspection JSValidateTypes
  return array.reduce(function(accumulated, current) {
    const valueToPluck = current[key];
    if (valueToPluck) {
      accumulated.push(valueToPluck);
    }
    return accumulated;
  }, []);
}