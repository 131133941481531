import { ref, watch } from 'vue';

export function useApplicationFilters(applications, { dateKeyToFilterBy = 'createdAt' } = {}) {
  const filteredApplications = ref(applications.value);
  watch(applications, () => (filteredApplications.value = applications.value));

  const defaultFilters = {
    dateFrom: null,
    dateTo: null,
    type: null,
    reason: null,
  };

  const filters = ref({ ...defaultFilters });
  const resetFilters = () => {
    filters.value = { ...defaultFilters };
    /** Присваивание вместо applyFilters для большей скорости */
    filteredApplications.value = applications.value;
  };
  const applyFilters = () => {
    filteredApplications.value = applications.value.filter((application) => {
      const { dateFrom, dateTo, type, reason } = filters.value;
      let keep = true;
      if (dateFrom) {
        keep = keep && new Date(application[dateKeyToFilterBy]).getTime() >= dateFrom.getTime();
      }
      if (dateTo) {
        keep = keep && new Date(application[dateKeyToFilterBy]).getTime() < getNextDayDate(dateTo).getTime();
      }
      if (type) {
        keep = keep && application.type === type;
      }
      if (reason) {
        keep = keep && application.reasons.some((r) => r.id === reason);
      }
      return keep;
    });
  };

  return {
    filters,
    filteredApplications,
    applyFilters,
    resetFilters,
  };
}

/**
 * @param {Date} date
 */
function getNextDayDate(date) {
  date.setDate(date.getDate() + 1);
  return date;
}