import UserLayout from '@/layouts/UserLayout';
import MainPageLayout from '@/layouts/user/MainPageLayout';
import GeneralInfo from '@/views/user/GeneralInfo';
import Branches from '@/views/user/Branches';
import Licenses from '@/views/user/Licenses';
import AccreditationCertificate from '@/views/user/AccreditationCertificate';
import AccreditationsLayout from '@/layouts/user/AccreditationsLayout';
import NewApplications from '@/views/user/NewApplications';
import SentApplications from '@/views/user/SentApplications';
import FixApplications from '@/views/user/FixApplications';
import Notifications from '@/views/user/Notifications';
import CreateApplication from '@/views/user/applications/application/CreateApplication';
import ApplicationLayout from '@/layouts/user/ApplicationLayout';
import ApplicationProperties from '@/views/user/applications/application/show/ApplicationProperties';
import Requests from '@/views/user/applications/Requests';
import ApplicationDocuments from '@/views/user/applications/application/show/ApplicationDocuments';
import EducationalPrograms from '@/views/user/applications/application/EducationalPrograms';
import Request from '@/views/user/Request';
import ApplicationBranches from '@/views/user/applications/application/show/ApplicationBranches';
import ApplicationCorrection from '@/views/user/applications/application/show/ApplicationCorrection';
import MonitoringLayout from '@/layouts/user/MonitoringLayout';
import MonitoringTasks from '@/views/user/MonitoringTasks';
import MonitoringTaskPrograms from '@/views/user/MonitoringTaskPrograms';
import MonitoringProgramMarksLayout from '@/layouts/user/MonitoringProgramMarksLayout';
import MonitoringProgramMarks from '@/views/user/MonitoringProgramMarks';

export const routes = [
  {
    path: '/eo',
    component: UserLayout,
    redirect: { name: 'home' },
    meta: {
      requiresAuth: true,
      role: 'user',
    },
    children: [
      {
        path: 'home',
        component: MainPageLayout,
        children: [
          {
            path: '',
            name: 'home',
            component: GeneralInfo,
          },
          {
            path: 'branches',
            name: 'branches',
            component: Branches,
          },
          {
            path: 'licenses',
            name: 'licenses',
            component: Licenses,
          },
          {
            path: 'certificate',
            name: 'accreditation-certificate',
            component: AccreditationCertificate,
          },
        ],
      },
      {
        path: 'accreditation',
        component: AccreditationsLayout,
        children: [
          {
            path: '',
            component: NewApplications,
            name: 'user.applications.new',
          },
          {
            path: 'sent',
            component: SentApplications,
            name: 'user.applications.sent',
          },
          {
            path: 'require-fix',
            component: FixApplications,
            name: 'user.applications.require-fix',
          },
          {
            path: 'notifications',
            component: Notifications,
            name: 'user.applications.notifications',
          },
          {
            path: 'requests',
            component: Requests,
            name: 'user.applications.requests',
          },
        ],
      },
      {
        path: 'accreditation/application/create',
        component: CreateApplication,
        name: 'user.application.create',
      },
      {
        path: 'accreditation/requests/:id',
        component: Request,
        props: true,
        name: 'user.request',
      },
      {
        path: 'accreditation/application/:id',
        component: ApplicationLayout,
        props: true,
        children: [
          {
            path: '',
            component: ApplicationProperties,
            name: 'user.application.show',
          },
          {
            path: 'branches',
            component: ApplicationBranches,
            name: 'user.application.show.branches',
            props: true,
          },
          {
            path: 'programs',
            component: EducationalPrograms,
            name: 'user.application.show.programs',
            props: true,
          },
          {
            path: 'correction',
            component: ApplicationCorrection,
            name: 'user.application.show.correction',
            props: true,
          },
          {
            path: 'documents',
            component: ApplicationDocuments,
            props: true,
            name: 'user.applications.show.documents',
          },
        ],
      },
      {
        path: 'monitoring',
        component: MonitoringLayout,
        children: [
          {
            path: '',
            name: 'monitoring.tasks',
            component: MonitoringTasks,
          },
          {
            path: 'task-programs/:id',
            name: 'monitoring.task-programs',
            component: MonitoringTaskPrograms,
          },
        ],
      },
      {
        path: 'monitoring',
        component: MonitoringProgramMarksLayout,
        children: [
          {
            path: 'program-marks/:id',
            component: MonitoringProgramMarks,
            name: 'monitoring.program-marks',
          },
        ],
      },
    ],
  },
];
