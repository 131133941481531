<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--full">
              <input-text label="Тип заявления" :model-value="application.type" is-disabled/>
            </div>
            <div class="col col--full">
              <div class="form-label">Основания заявления</div>
              <div class="form-group">
                <switch-input
                  v-for="reason in application.reasons"
                  :key="reason.id"
                  type="checkbox"
                  size="m"
                  :label="reason.name"
                  :model-value="true"
                  disabled
                />
              </div>
            </div>
            <div class="col col--half">
              <input-text label="Предыдущее свидетельство" :model-value="certificate" is-disabled/>
            </div>
            <div class="col col--half">
              <input-text label="Действующая лицензия" :model-value="license" is-disabled/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main__block">
      <div class="block">
        <div class="block__top">
          <div class="block-title">Информирование о ходе процедуры</div>
        </div>

        <div class="block__main">
          <div class="row row--aife">
            <div class="col col--half">
              <input-text
                label="Уполномоченное лицо"
                :model-value="application.more?.contact?.name"
                is-disabled
              />
            </div>
            <div class="col col--fourth">
              <input-text
                label="Телефон"
                :model-value="application.more?.contact?.phoneNumber"
                is-disabled
              />
            </div>
            <div class="col col--fourth">
              <input-text
                label="E-mail"
                :model-value="application.more?.contact?.email"
                is-disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="application.status === null || application.status === 'Требуется загрузка копии заявления'" class="main__footer main__footer--sticky">
    <div class="line line--jcsb">
      <div class="line__item">
        <custom-button label="Удалить заявление" icon="delete" color="gray" @click="removeApplication"/>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from '@/components/InputText';
import SwitchInput from '@/components/SwitchInput';
import CustomButton from '@/components/CustomButton';
import { Applications } from '@/repositories';
export default {
  name: "ApplicationProperties",
  components: { InputText, SwitchInput, CustomButton },
  computed: {
    reasons() {
      return this.application.reasons?.map(reason => reason.name || '').join('; ');
    },
    certificate() {
      const certificate = this.application.certificate;
      if (!certificate) {
        return '';
      }

      const date = certificate.issuedAt && this.$filters.formatDate(certificate.issuedAt);
      return `№ ${certificate.registrationNumber} от ${date} (${certificate.status?.name || ''})`;
    },
    license() {
      const license = this.application.license;
      if (!license) {
        return '';
      }

      const date = license.orderDate && this.$filters.formatDate(license.orderDate);
      return `№ ${license.registrationNumber} от ${date} (${license.status.name})`;
    },
  },
  methods: {
    removeApplication() {
      if (!window.confirm('Вы действительно хотите удалить заявление?')) {
        return;
      }

      Applications
        .remove(this.application.id)
        .then(() => this.$router.push({ name: 'user.applications.new' }))
        .catch(() => window.alert('Не удалось удалить заявление. Попробуйте, пожалуйста, позже!'));
    }
  },
  props: {
    application: {
      type: Object,
      default: () => ({}),
    },
    editable: Object,
  }
}
</script>
