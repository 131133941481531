<template>
  <div class="wrap" :class="{ collapsed }">
    <main class="main">
      <div class="main__wrap main__wrap--single">
        <div class="main__header">
          <div class="main__title">
            <div class="title">
              Запрос №{{ request.id }} от
              {{ $filters.formatDate(request.createdAt) }}
            </div>
          </div>
        </div>
        <div class="main__grid">
          <!--    Сайдбар для выбора типа образовательной программы      -->
          <div class="main__side">
            <div class="form-label">Филиалы</div>
            <div class="side-nav">
              <ul class="side-nav__list" v-if="request.application">
                <li
                  class="side-nav__item"
                  v-for="branch in request.application.programsBranches"
                  :key="branch.id"
                >
                  <details open="">
                    <summary>
                      {{ branch.name }}
                    </summary>
                    <ul class="side-nav__list side-nav__list--sub">
                      <li
                        class="side-nav__item"
                        v-for="type in branch.applicationProgramsTypes"
                        :key="type.id"
                      >
                        <a
                          href="javascript:"
                          class="sub-link"
                          :class="{
                            'sub-link--active':
                              branch.id === selectedBranch?.id && type.id === selectedType?.id,
                          }"
                          @click="selectType(branch, type)"
                        >
                          <span class="sub-link__label">{{ type.name }}</span>
                        </a>
                      </li>
                    </ul>
                  </details>
                </li>
              </ul>
            </div>
          </div>

          <div class="main__body">
            <div class="main__block">
              <div class="form-label">Детали запроса</div>

              <div class="tbl-wrap">
                <table class="tbl">
                  <thead>
                    <tr>
                      <th>Код УГСН</th>
                      <th>Наименование УГСН</th>
                      <th>Код ОП</th>
                      <th>Наименование ОП</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="tbl-link"
                      role="button"
                      v-for="program in programs"
                      :key="program.id"
                      @click="showModalForProgram(program)"
                    >
                      <td>{{ program.egs.code }}</td>
                      <td>{{ program.egs.name }}</td>
                      <td>{{ program.code }}</td>
                      <td>{{ program.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="main__footer main__footer--sticky" v-if="requestEditable">
          <div class="line line--jcfe">
            <div class="line__item">
              <custom-button label="Отправить" @click="completeRequest"/>
            </div>
          </div>
        </div>
      </div>
    </main>
    <requested-documents
      :open="modal.open"
      :education-program="modal.program"
      :education-type="modal.educationType"
      :request-id="id"
      @close="closeModal"
      :editable="requestEditable"
    />
  </div>
</template>

<script>
import { Request } from '@/repositories/user/Request';
import RequestedDocuments from '@/components/modals/RequestedDocuments';
import CustomButton from '@/components/CustomButton';
import { ValidationError } from '@/exceptions';
export default {
  name: 'Request',
  components: { RequestedDocuments, CustomButton },
  created() {
    this.fetchRequest();
  },
  watch: {
    id() {
      this.fetchRequest();
    },
  },
  data() {
    return {
      request: {},
      selectedBranch: null,
      selectedType: null,
      programs: [],
      modal: {
        open: false,
        program: null,
        educationType: null,
      },
    };
  },
  computed: {
    collapsed() {
      return this.$store.state.sidebar.collapsed;
    },
    requestEditable() {
      return this.request.statusId === 1;
    }
  },
  methods: {
    async fetchRequest() {
      this.request = await Request.get(this.id);
    },
    async fetchPrograms() {
      this.programs = await Request.programs(this.id, this.selectedBranch.id, this.selectedType.id);
    },
    selectType(branch, type) {
      this.selectedBranch = branch;
      this.selectedType = type;
      this.fetchPrograms();
    },
    showModalForProgram(program) {
      this.modal.open = true;
      this.modal.program = program;
      this.modal.educationType = this.selectedType;
    },
    closeModal() {
      this.modal.open = false;
    },
    completeRequest() {
      Request
        .fulfill(this.id)
        .then(this.redirectToRequests)
        .catch(error => {
          if (error instanceof ValidationError) {
            return window.alert(error.getAllErrors().join('\n'));
          }
          return window.alert('Не удалось отправить запрос. Попробуйте, пожалуйста, позже!');
        });
    },
    redirectToRequests() {
      this.$router.push({ name: 'user.applications.requests' });
    }
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
