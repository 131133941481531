<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__top">
          <div class="block-title">Документы к заявлению</div>
        </div>
        <notice icon="info" type="info">
          Уважаемые коллеги! Убедительная просьба грузить в каждое поле один файл. Если у вас есть необходимость загрузить несколько файлов одного типа, объедините их, пожалуйста, в архив. Поля, отмеченные «звёздочкой» (<b class="danger">*</b>) являются обязательными.
        </notice>
        <div class="block__main">
          <div class="row row--aife">
            <template v-for="type in types" :key="type.id">
              <div class="col col--full">
                <template v-if="editable.editable">
                  <application-file-uploader
                    v-if="type.allowed_file_types.find(fileType => fileType.id === $options.FILE_TYPES.FILE)"
                    :application-id="id"
                    :document-type-id="type.id"
                    :label="type.name"
                    :required="type.required"
                    :has-files="!!filesByType[type.id]?.length"
                    @uploaded="getFiles(id)"
                  />
                  <input-text
                    v-else-if="type.allowed_file_types.find(fileType => fileType.id ===  $options.FILE_TYPES.LINK)"
                    :label="type.name"
                    v-model="linksByType[type.id]"
                    placeholder="Укажите ссылку на документ на официальном сайте организации"
                    :required="type.required"
                    button="save"
                    @click:button="storeLink(linksByType[type.id], type.id)"
                  />
                </template>
                <template v-else>
                  <div class="form-label">{{ type.name }}</div>
                  <InputText
                    v-if="linksByType[type.id]"
                    is-disabled
                    :model-value="linksByType[type.id]"
                  />
                </template>
                <div class="files">
                  <downloadable-file
                      v-for="file in filesByType[type.id]"
                      :key="file.id"
                      :name="file.name"
                      :link="file.path"
                      :deletable="editable.editable && application.status !== 'Уведомление о несоответствии подписано'"
                      @delete="deleteFile(file.id)"
                  />
                </div>
              </div>
              <div v-if="type.id === $options.STATE_SECRET_LICENSE_ID" class="col col--full">
                <state-secret-license @submit="updateLicense" v-bind="license"/>
              </div>
            </template>
            <div class="col col--full">
              <application-copy-uploader v-if="editable.editable" :application-id="id" label="Копия заявления" :required="true" :has-files="!!copies?.length" @uploaded="getCopies"/>
              <div v-else class="form-label">Копия заявления</div>
              <div class="files">
                <downloadable-file
                  v-for="copy in copies"
                  :key="copy.id"
                  :name="copy.name"
                  :link="copy.path"
                  :deletable="editable.editable && application.status !== 'Уведомление о несоответствии подписано'"
                  @delete="deleteCopy(copy.id)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main__footer main__footer--sticky">
    <div class="line">
      <div class="line__item line__item--mra">
        <custom-button :type="2" label="Распечатать заявление" icon="print" @click="getCopyTemplate"/>
      </div>
      <div class="line__item">
        <custom-button v-if="applicationCanBeSent && editable.editable" label="Отправить" @click="sendApplication"/>
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton';
import { Applications } from '@/repositories';
import ApplicationFileUploader from '@/components/ApplicationFileUploader';
import ApplicationCopyUploader from '@/components/temp/ApplicationCopyUploader';
import DownloadableFile from '@/components/DownloadableFile';
import { downloadFile } from '@/utils/helpers/downloadFile';
import Notice from '@/components/Notice';
import StateSecretLicense from '@/components/blocks/StateSecretLicense';
import {groupBy} from "@/utils/helpers";
import InputText from '@/components/InputText';
export default {
  name: 'ApplicationDocuments',
  components: {
    InputText,
    StateSecretLicense,
    CustomButton,
    ApplicationFileUploader,
    DownloadableFile,
    ApplicationCopyUploader,
    Notice,
  },
  created() {
    this.getDocumentTypes(this.id);
    this.getFiles(this.id);
    this.getCopies();
    this.fetchLicense();
  },
  watch: {
    id(id) {
      this.getDocumentTypes(id);
      this.getFiles(id);
      this.getCopies();
      this.fetchLicense();
    },
  },
  data() {
    return {
      types: [],
      filesByType: {},
      linksByType: {},
      copy: null,
      copies: [],
      license: {},
    };
  },
  STATE_SECRET_LICENSE_ID: 'F4C6F206-CD0A-48B9-9269-E1CD6BADCA45',
  FILE_TYPES: { FILE: 1, LINK: 2},
  computed: {
    applicationCanBeSent() {
      return this.types
        .filter((type) => type.required)
              /* TODO Добавить проверку, что данные в ссылке были сохранены в БД */
        .every((type) => (this.filesByType[type.id] && this.filesByType[type.id].length > 0) || this.linksByType[type.id])
        && Boolean(this.copies.length);
    },
  },
  methods: {
    async getDocumentTypes(applicationId) {
      this.types = await Applications.getDocumentTypes(applicationId);
    },
    async getFiles(applicationId) {
      let files = await Applications.getFiles(applicationId);
      this.filesByType = groupBy(
        files.filter((file) => !file.is_link),
        'type.id'
      );
      files
        .filter((file) => file.is_link)
        .forEach((link) => {
          if (link?.type?.id) {
            this.linksByType[link.type.id] = link.name;
          }
        });
    },
    deleteFile(fileId) {
      if (!window.confirm('Удалить документ?')) {
        return;
      }
      Applications.removeFile(fileId).then(() => this.getFiles(this.id));
    },
    async getCopy() {
      this.copy = await Applications.getCopy(this.id);
    },
    async getCopies() {
      this.copies = await Applications.getCopies(this.id);
    },
    async getCopyTemplate() {
      const template = await Applications.getCopyTemplate(this.id);
      downloadFile(template, template.name);
    },
    deleteCopy(copyId) {
      if (!window.confirm('Удалить документ?')) {
        return;
      }
      Applications
        .removeCopy(this.id, copyId)
        .then(this.getCopies);
    },
    sendApplication() {
      Applications
        .send(this.id)
        .then(() => this.$router.push({name: 'user.applications.sent'}));
    },
    updateLicense(license) {
      Applications
        .updateSecretLicense(this.id, license)
        .catch(() => window.alert('Не удалось сохранить данные лицензии. Попробуйте, пожалуйста, позже!'));
    },
    fetchLicense() {
      Applications
        .secretLicense(this.id)
        .then(license => {
          if (license.issuedAt) {
            license.issuedAt = new Date(license.issuedAt);
          }
        if (license.expiresAt) {
            license.expiresAt = new Date(license.expiresAt);
          }
          this.license = license;
        });
    },
    storeLink(url, documentTypeId) {
      console.log(url, documentTypeId);
      if (!url) {
        this.removeLink(documentTypeId);
      }

      try {
        Applications.storeDocumentLink(this.id, documentTypeId, url);
      } catch {
        window.alert('Не удалось сохранить ссылку на документ. Попробуйте, пожалуйста, позже!');
      }
    },
    removeLink(documentTypeId) {
      try {
        Applications.removeLinkByType(this.id, documentTypeId);
      } catch {
        window.alert('Не удалось удалить ссылку на документ. Попробуйте, пожалуйста, позже!');
      }
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    editable: Object,
    application: Object,
  },
};
</script>
