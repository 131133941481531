<template>
  <div class="form-group">
    <div class="form-label" :class="{ 'form-label--required': required }">{{ label }}</div>
    <form-field
      class="form-field--files form-field--2 form-field--s form-field--primary"
      :class="{ 'has-error': errors.length || (required && !hasFiles) }"
    >
      <div class="file-attach">
        <div class="file-attach__main">
          <template v-if="files.length || errors.length">
            <div class="file-attach__cover" :class="{ 'file-attach__cover--error': errors.length }">
              <div class="loader">
                <SvgIcon v-if="errors.length" name="warning" :size="24" />
                <SvgClr v-else-if="files.length" name="loader" :size="24" />
              </div>
            </div>
          </template>
          <div class="file-attach__placeholder">{{ placeholder }}</div>
        </div>
        <label class="file-attach__label">
          <input type="file" @change="uploadFiles" ref="input" accept=".sig" multiple class="file-attach__input"/>
          <span class="file-attach__btn">Обзор</span>
        </label>
      </div>
    </form-field>
    <div v-if="errors.length || (required && !hasFiles)" class="error-text error-text--right">
      <template v-for="error in errors" :key="error">
        {{ error }} <br/>
      </template>
      <template v-if="required && !hasFiles">Пожалуйста, приложите документы</template>
    </div>
  </div>
</template>

<script>
import FormField from '@/components/FormField';
import SvgClr from '@/components/SvgClr';
import SvgIcon from '@/components/SvgIcon';
import { Applications } from '@/repositories';
import { ValidationError } from '@/exceptions';
export default {
  name: 'ApplicationFileUploader',
  components: { FormField, SvgClr, SvgIcon },
  data() {
    return {
      files: [],
      errors: [],
    };
  },
  computed: {
    placeholder() {
      return this.files.length
        ? this.files.map(file => file.name).join(', ')
        : 'Прикрепите файл';
    }
  },
  methods: {
    uploadFiles(event) {
      const files = event.target.files;
      this.files = [...files];
      if (!files.length) {
        return;
      }
      this.errors = [];
      Applications.addFiles(this.applicationId, [...files], this.documentTypeId)
        .then(() => this.$emit('uploaded'))
        .then(this.resetSelectedFiles)
        .catch(this.handleUploadError);
    },
    handleUploadError(error) {
      if (!(error instanceof ValidationError)) {
        throw error;
      }

      /* TODO добавить имя файла к сообщению об ошибке в нем */
      this.errors = error.getAllErrors();
    },
    resetSelectedFiles() {
      this.files = [];
      this.$refs.input.value = '';
    }
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    documentTypeId: {
      type: String,
      required: true,
    },
    required: Boolean,
    label: String,
    hasFiles: Boolean,
  },
  emits: ['uploaded'],
};
</script>
