<template>
  <main class="main">
    <div class="main__notify" :hidden="notifyClass === ''">
      <div class="r-notify" :class="notifyClass">
        <div class="r-notify__icon">
          <svg-icon name="check-filled" :size="24" />
        </div>
        <div class="r-notify__text">
          {{ notifyClass === 'r-notify--success' ? 'Данные сохранены!' : 'Данные не сохранены!' }}
        </div>
      </div>
    </div>

    <div class="main__wrap main__wrap--wide">
      <div class="main__top">
        <div class="main__title">
          <div class="title">Образовательные программы</div>
        </div>
      </div>
      <div class="main__body">
        <div class="main__block">
          <div class="block">
            <div class="block__main">
              <table class="tbl tbl--vat tbl--sticky-head">
                <thead>
                  <tr>
                    <th style="width: 40px">№</th>
                    <th v-if="isHigherLevel(levelId) || isSecondaryVocationalLevel(levelId)">
                      Код и наименование УГСН
                    </th>
                    <th>Уровень образования</th>
                    <th v-if="isHigherLevel(levelId) || isSecondaryVocationalLevel(levelId)">
                      Код и наименование профессии/специальности/направления подготовки
                    </th>
                    <th v-if="isHigherLevel(levelId) || isSecondaryVocationalLevel(levelId)">
                      Наименование образовательной программы
                    </th>
                    <th v-if="isHigherLevel(levelId)">Наличие набора в 2022 году</th>
                    <th v-if="isHigherOrSecondaryVocationalLevel(levelId)">
                      Наличие выпуска в 2021 году (ВО, СПО)
                    </th>
                    <th v-if="isSecondaryVocationalOrSchoolLevel(levelId)">
                      Наличие выпуска в 2022 году (СПО, общее образование)
                    </th>
                    <th v-if="isHigherLevel(levelId)">ДВИ</th>
                    <th v-if="isSecondaryVocationalLevel(levelId)">Демо</th>
                    <th v-if="!isGeneralLevel(levelId)" style="width: 188px">
                      Приоритетная форма обучения
                    </th>
                    <th v-if="isSecondaryVocationalLevel(levelId)">База набора</th>
                    <!-- <th v-if="isGeneralLevel(levelId)">Адаптированная</th> -->
                    <th>Статус</th>
                    <!-- <th style="width: 64px">Баллы</th> -->
                    <th>Управление</th>
                  </tr>
                </thead>
                <tbody v-if="programs.length">
                  <tr
                    class="tbl-status"
                    v-for="program in programs"
                    :key="program.id"
                    :data-filled="program.complete || program.is_rejected ? true : null"
                  >
                    <td>{{ programs.indexOf(program) + 1 }}</td>
                    <td v-if="isHigherLevel(levelId) || isSecondaryVocationalLevel(levelId)">
                      {{ program.ugs_code }} –
                      {{ program.ugs_name }}
                    </td>
                    <td>{{ program.edu_level }}</td>
                    <td v-if="isHigherLevel(levelId) || isSecondaryVocationalLevel(levelId)">
                      {{ program.licensed_program_code }} – {{ program.licensed_program_name }}
                    </td>
                    <td
                      v-if="isHigherLevel(levelId) || isSecondaryVocationalLevel(levelId)"
                      :class="{ danger: isEduProgramNameNotFilled(program) }"
                    >
                      {{
                        isEduProgramNameNotFilled(program)
                          ? 'Нажмите на кнопку «Замена» и заполните наименование образовательной программы'
                          : program.edu_program_name
                          ? program.edu_program_name
                          : '–'
                      }}
                    </td>
                    <td v-if="isHigherLevel(levelId)">
                      <custom-select
                        placeholder="Выберите"
                        :options="admissionFormats"
                        v-model="programsAdmissions[program.id]"
                        @update:modelValue="
                          updateAdmission(program.id, programsAdmissions[program.id])
                        "
                        :isDisabled="isTaskFinished || program.is_rejected || program.is_sent"
                      />
                    </td>
                    <td v-if="isHigherOrSecondaryVocationalLevel(levelId)">
                      <custom-select
                        placeholder="Выберите"
                        :options="graduationFormats"
                        v-model="programsGraduationsIn2021[program.id]"
                        @update:modelValue="
                          updateGraduationIn2021(program.id, programsGraduationsIn2021[program.id])
                        "
                        :isDisabled="isTaskFinished || program.is_rejected || program.is_sent"
                      />
                    </td>
                    <td v-if="isSecondaryVocationalOrSchoolLevel(levelId)">
                      <custom-select
                        placeholder="Выберите"
                        :options="graduationFormats"
                        v-model="programsGraduationsIn2022[program.id]"
                        @update:modelValue="
                          updateGraduationIn2022(program, programsGraduationsIn2022[program.id])
                        "
                        :isDisabled="isTaskFinished || program.is_rejected || program.is_sent"
                      />
                    </td>

                    <td v-if="isHigherLevel(levelId)">
                      <switch-input
                        type="checkbox"
                        size="m"
                        v-model="activeProgramsWithDvi"
                        :value="String(program.id)"
                        @update:modelValue="updateDvi(String(program.id), !program.dvi)"
                        :disabled="isTaskFinished || program.is_rejected || program.is_sent"
                      />
                    </td>
                    <td v-if="isSecondaryVocationalLevel(levelId)">
                      <custom-select
                        placeholder="Выберите"
                        :options="demoFormats"
                        v-model="programDemos[program.id]"
                        @update:modelValue="updateDemo(program.id, programDemos[program.id])"
                        :isDisabled="
                          isTaskFinished ||
                          blockedDemos[program.id] ||
                          program.is_rejected ||
                          program.is_sent
                        "
                      />
                    </td>
                    <td v-if="!isGeneralLevel(levelId)">
                      <custom-select
                        placeholder="Выберите"
                        :options="eduFormats"
                        v-model="programsEduFormats[program.id]"
                        @update:modelValue="
                          updateEduFormat(program.id, programsEduFormats[program.id])
                        "
                        :isDisabled="isTaskFinished || program.is_rejected || program.is_sent"
                      />
                    </td>
                    <td v-if="isSecondaryVocationalLevel(levelId)">
                      <custom-select
                        placeholder="Выберите"
                        :options="recruitBase"
                        v-model="recruitBases[program.id]"
                        @update:modelValue="updateRecruitBase(program.id, recruitBases[program.id])"
                        :isDisabled="isTaskFinished || program.is_rejected || program.is_sent"
                      />
                    </td>
                    <!--  <td v-if="isGeneralLevel(levelId)">
                      <switch-input
                        type="checkbox"
                        size="m"
                        v-model="activeAdaptedPrograms"
                        :value="String(program.id)"
                        @update:modelValue="
                          updateIsAdaptedFlag(String(program.id), !program.is_adapted)
                        "
                        :disabled="program.is_rejected || program.is_sent"
                      />
                    </td> -->
                    <td>
                      {{
                        program.is_sent ? 'Завершен' : program.is_rejected ? 'Отказ' : 'Ввод данных'
                      }}
                    </td>
                    <!-- <td>{{ isTaskFinished ? program.score : '' }}</td> -->
                    <td>
                      <div class="list list--2xs">
<!-- Временно скрыта кнопка                        -->
<!--                        <div-->
<!--                          v-if="isTaskFinished && !program.everyMarkHasReachedThreshold"-->
<!--                          class="list__item"-->
<!--                        >-->
<!--                          <CustomButton-->
<!--                            label="Скачать рекомендации"-->
<!--                            :type="3"-->
<!--                            @click="downloadRecommendations(program)"-->
<!--                          />-->
<!--                        </div>-->
                        <div v-if="isTaskFinished" class="list__item">
                          <button
                            @click.prevent="redirectToProgramsMarks(program.id)"
                            class="btn btn--3 btn--s btn--primary"
                          >
                            Просмотр
                          </button>
                        </div>

                        <div v-if="!program.is_rejected && !isTaskFinished" class="list__item">
                          <button
                            href="#"
                            class="btn btn--3 btn--s btn--primary"
                            @click.prevent="redirectToProgramsMarks(program.id)"
                            :disabled="
                              isEduProgramNameNotFilled(program) ||
                              isHigherLevelAdmissionOrGraduationIn2021NotFilled(program) ||
                              isFillButtonDisabledForSecondaryVocationalLevel(program) ||
                              isFillButtonDisabledForGeneralLevel(program)
                            "
                          >
                            <span class="btn__label">{{
                              program.is_sent ? 'Просмотр' : 'Заполнить'
                            }}</span>
                          </button>
                        </div>

                        <div
                          v-if="
                            !isTaskFinished &&
                            !program.is_sent &&
                            !program.is_rejected &&
                            isHigherOrSecondaryVocationalLevel(program.level_id)
                          "
                          class="list__item"
                        >
                          <button
                            href="#"
                            class="btn btn--3 btn--s btn--primary"
                            @click.prevent="openReplaceProgramModal(program)"
                          >
                            <span class="btn__label">Замена</span>
                          </button>
                        </div>

                        <div
                          v-if="!isTaskFinished && !program.is_sent && !program.is_rejected"
                          class="list__item"
                        >
                          <button
                            href="#"
                            class="btn btn--3 btn--s btn--primary"
                            @click.prevent="openRejectProgramModal(program)"
                            :disabled="program.is_sent"
                          >
                            <span class="btn__label">Отказ</span>
                          </button>
                        </div>

                        <div v-if="!program.is_rejected && programs.length" class="list__item">
                          <button
                            href="#"
                            class="btn btn--3 btn--s btn--primary"
                            @click.prevent="generateDataReportTemplate(program.id)"
                          >
                            <span class="btn__label">Предпросмотр</span>
                          </button>
                        </div>
                        <div
                          v-if="
                            !isTaskFinished &&
                            !program.is_sent &&
                            !program.is_rejected &&
                            programs.length
                          "
                          class="list__item"
                        >
                          <button
                            href="#"
                            class="btn btn--3 btn--s btn--primary"
                            @click.prevent="openSendModal(program)"
                            :disabled="program.is_rejected || !program.complete || program.is_sent"
                          >
                            <span class="btn__label">Отправить</span>
                          </button>
                        </div>
                        <div v-if="!program.is_rejected" class="list__item">
                          <button
                            href="#"
                            class="btn btn--3 btn--s btn--primary"
                            @click.prevent="generateMonitoringResultReportTemplate(program.id)"
                            :disabled="!program.is_sent"
                          >
                            <span class="btn__label">Сформировать отчет</span>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="block__bottom">
              <div class="line">
                <div
                  v-if="!isTaskFinished && isHigherOrSecondaryVocationalLevel"
                  class="line__item"
                >
                  <custom-button
                    label="Добавить образовательную программу"
                    :type="2"
                    @click="openChoiceBetweenProgramModal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!programs.length && !isLoading" class="main__block">
          <div class="blank">
            <div class="blank__cover">
              <svg-clr name="accreditation-monitoring" :size="24" />
            </div>
            <div class="blank__text">Данные отсутствуют</div>
          </div>
        </div>
      </div>

      <div v-if="isLoading" class="main__over">
        <div class="loader loader--l">
          <svg-clr name="loader" :size="24" />
        </div>
      </div>
    </div>
  </main>

  <div v-if="isSendModalOpen" class="modal" :class="{ open: isSendModalOpen }">
    <div class="modal-close modal__bg" @click="isSendModalOpen = false"></div>

    <div class="modal-box modal-box--sm">
      <div class="modal-box__top">
        <div class="modal-box__header">
          <div class="modal-box__title">Укажите файл отчета для отправки</div>
        </div>
        <button class="link link--gray modal-close" type="button" @click="isSendModalOpen = false">
          <svg-icon name="cross" :size="24" />
        </button>
      </div>

      <div class="modal-box__main">
        <div class="modal-box__body">
          <div class="row row--aife">
            <div class="col col--full">
              <file-attach
                label="Внимание! Для отправки принимаются только файлы в формате PDF!"
                v-model="file"
                accept=".pdf"
                isRequired
              />
            </div>

            <div v-if="cryptoPro.isLoading" class="col col--full">
              <div class="loader">
                <svg-clr name="loader" :size="24" />
              </div>
            </div>
            <div v-if="cryptoPro.isLoaded && !cryptoPro.isValidSystemSetup" class="col col--full">
              {{ cryptoPro.error ?? 'Не удалось загрузить плагин для подписания отчета' }}
            </div>
            <template v-if="cryptoPro.isLoaded && cryptoPro.isValidSystemSetup">
              <div v-if="cryptoPro.certificates.isLoading" class="col col--full">
                <div class="loader">
                  <svg-clr name="loader" :size="24" />
                </div>
              </div>
              <div v-if="cryptoPro.certificates.isLoaded" class="col col--full">
                <custom-select
                  label="Сертификат"
                  placeholder="Выберите сертификат для подписи отчёта"
                  :options="cryptoPro.certificates.data.map(certificateToOption)"
                  v-model="certificateThumbprint"
                  :vuelidate-errors="
                    cryptoPro.certificates.error ? [{ $message: cryptoPro.certificates.error }] : undefined
                  "
                />
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="modal-box__bottom">
        <div class="line">
          <div class="line__item">
            <custom-button label="Закрыть" :type="2" @click="isSendModalOpen = false" />
          </div>
          <div class="line__item line__item--mla">
            <custom-button
              :label="certificateThumbprint ? 'Подписать и отправить' : 'Отправить'"
              @click="sendProgram(activeProgram.id)"
              :is-loading="isSaveButtonLoading"
              :disabled="isSaveButtonLoading || !file"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{ open: isChoiceBetweenProgramModalOpen }">
    <div class="modal-close modal__bg" @click="isChoiceBetweenProgramModalOpen = false"></div>

    <div class="modal-box modal-box--sm">
      <div class="modal-box__top">
        <div class="modal-box__header">
          <div class="modal-box__title">
            Добавить образовательную программу по общему образованию?
          </div>
        </div>
        <button
          class="link link--gray modal-close"
          type="button"
          @click="isChoiceBetweenProgramModalOpen = false"
        >
          <svg-icon name="cross" :size="24" />
        </button>
      </div>

      <div class="modal-box__bottom">
        <div class="line">
          <div class="line__item">
            <custom-button label="Нет" @click="openAddProgramModal" />
          </div>
          <div class="line__item line__item--mla">
            <custom-button label="Да" @click="openAddBasicProgramModal" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{ open: isAddBasicProgramModalOpen }">
    <div class="modal-close modal__bg" @click="isAddBasicProgramModalOpen = false"></div>

    <div class="modal-box modal-box--sm">
      <div class="modal-box__top">
        <div class="modal-box__header">
          <div class="modal-box__title">
            Добавить образовательную программу по общему образованию
          </div>
        </div>
        <button
          class="link link--gray modal-close"
          type="button"
          @click="isAddBasicProgramModalOpen = false"
        >
          <svg-icon name="cross" :size="24" />
        </button>
      </div>

      <div class="modal-box__main">
        <div class="modal-box__body">
          <div class="modal-box__block" style="margin-bottom: 100px">
            <custom-select
              label="Выберите уровень образования"
              placeholder="Выберите"
              :options="basicEduLevels"
              v-model="addingBasicProgramForm.eduLevel"
            />
          </div>
        </div>
      </div>

      <div class="modal-box__bottom">
        <div class="line">
          <div class="line__item">
            <custom-button label="Отмена" :type="2" @click="isAddBasicProgramModalOpen = false" />
          </div>
          <div class="line__item line__item--mla">
            <custom-button
              label="Сохранить"
              @click="addBasicTaskProgram"
              :is-loading="isSaveButtonLoading"
              :disabled="isSaveButtonLoading || !isAddingBasicProgramFormFilled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{ open: isAddProgramModalOpen }">
    <div class="modal-close modal__bg" @click="isAddProgramModalOpen = false"></div>

    <div class="modal-box modal-box--sm">
      <div class="modal-box__top">
        <div class="modal-box__header">
          <div class="modal-box__title">Добавление образовательной программы</div>
        </div>
        <button
          class="link link--gray modal-close"
          type="button"
          @click="isAddProgramModalOpen = false"
        >
          <svg-icon name="cross" :size="24" />
        </button>
      </div>

      <div class="modal-box__main">
        <div class="modal-box__body">
          <custom-select
            label="Выберите УГСН"
            placeholder="Выберите"
            :options="ugsnIds"
            v-model="addingProgramForm.ugsnId"
            @update:modelValue="getLicensedProgramsByUgsnId(addingProgramForm.ugsnId)"
          />

          <custom-select
            label="Выберите образовательную программу"
            placeholder="Выберите"
            :options="licencedPrograms"
            v-model="addingProgramForm.licencedProgramId"
          />

          <input-text
            label="Введите наименование образовательной программы (профиля/направленности/специализации)"
            v-model="addingProgramForm.eduProgramName"
          />

          <input-text
            label="Причина добавления образовательной программы"
            v-model="addingProgramForm.addReason"
          />
        </div>
      </div>

      <div class="modal-box__bottom">
        <div class="line">
          <div class="line__item">
            <custom-button label="Отмена" :type="2" @click="closeAddProgramModal" />
          </div>
          <div class="line__item line__item--mla">
            <custom-button
              label="Сохранить"
              @click="addTaskProgram"
              :is-loading="isSaveButtonLoading"
              :disabled="isSaveButtonLoading || !addProgramInputsFilled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{ open: isReplaceProgramModalOpen }">
    <div class="modal-close modal__bg" @click="isReplaceProgramModalOpen = false"></div>

    <div class="modal-box modal-box--sm">
      <div class="modal-box__top">
        <div class="modal-box__header">
          <div class="modal-box__title">Замена образовательной программы</div>
        </div>
        <button
          class="link link--gray modal-close"
          type="button"
          @click="isReplaceProgramModalOpen = false"
        >
          <svg-icon name="cross" :size="24" />
        </button>
      </div>

      <div class="modal-box__main">
        <div class="modal-box__body">
          <div class="modal-box__block">
            <div class="subtitle">
              {{ activeProgram?.licensed_program_code }} {{ activeProgram?.licensed_program_name }}
            </div>
            <div class="subtitle subtitle--gray">{{ activeProgram?.edu_program_name }}</div>
          </div>

          <div class="modal-box__block">
            <custom-select
              label="Выберите профессию/специальность/направление подготовки"
              placeholder="Выберите"
              :options="licencedPrograms"
              v-model="replacingProgramForm.licencedProgramId"
            />

            <input-text
              label="Введите наименование образовательной программы (профиля/направленности/специализации)"
              v-model="replacingProgramForm.eduProgramName"
            />

            <input-text
              label="Причина замены образовательной программы"
              v-model="replacingProgramForm.replaceReason"
              :max-length="250"
            />
          </div>
        </div>
      </div>

      <div class="modal-box__bottom">
        <div class="line">
          <div class="line__item">
            <custom-button label="Отмена" :type="2" @click="closeReplaceProgramModal" />
          </div>
          <div class="line__item line__item--mla">
            <custom-button
              label="Сохранить"
              @click="replaceTaskProgram"
              :is-loading="isSaveButtonLoading"
              :disabled="isSaveButtonLoading || !replaceProgramInputsFilled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{ open: isRejectProgramModalOpen }">
    <div class="modal-close modal__bg" @click="isRejectProgramModalOpen = false"></div>

    <div class="modal-box modal-box--sm">
      <div class="modal-box__top">
        <div class="modal-box__header">
          <div class="modal-box__title">Отказ от образовательной программы</div>
        </div>
        <button
          class="link link--gray modal-close"
          type="button"
          @click="isRejectProgramModalOpen = false"
        >
          <svg-icon name="cross" :size="24" />
        </button>
      </div>

      <div class="modal-box__main">
        <div class="modal-box__block">
          <div class="subtitle">
            {{ activeProgram?.licensed_program_code }} {{ activeProgram?.licensed_program_name }}
          </div>
          <div class="subtitle subtitle--gray">{{ activeProgram?.edu_program_name }}</div>
        </div>

        <div class="modal-box__block">
          <div class="modal-box__body">
            <input-text
              label="Причина отказа от образовательной программы"
              v-model="rejectingProgramForm.rejectReason"
              :max-length="250"
            />
          </div>
        </div>
      </div>

      <div class="modal-box__bottom">
        <div class="line">
          <div class="line__item">
            <custom-button label="Отмена" :type="2" @click="closeRejectProgramModal" />
          </div>
          <div class="line__item line__item--mla">
            <custom-button
              label="Сохранить"
              @click="rejectTaskProgram"
              :is-loading="isSaveButtonLoading"
              :disabled="isSaveButtonLoading || !rejectProgramInputsFilled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" :class="{ open: isAddProgramModalOpen }">
    <div class="modal-close modal__bg" @click="isAddProgramModalOpen = false"></div>

    <div class="modal-box modal-box--sm">
      <div class="modal-box__top">
        <div class="modal-box__header">
          <div class="modal-box__title">Добавление образовательной программы</div>
        </div>
        <button
          class="link link--gray modal-close"
          type="button"
          @click="isAddProgramModalOpen = false"
        >
          <svg-icon name="cross" :size="24" />
        </button>
      </div>

      <div class="modal-box__main">
        <div class="modal-box__body">
          <custom-select
            label="Выберите УГСН"
            placeholder="Выберите"
            :options="ugsnIds"
            v-model="addingProgramForm.ugsnId"
            @update:modelValue="getLicensedProgramsByUgsnId(addingProgramForm.ugsnId)"
          />

          <custom-select
            label="Выберите образовательную программу"
            placeholder="Выберите"
            :options="licencedPrograms"
            v-model="addingProgramForm.licencedProgramId"
          />

          <input-text
            label="Введите наименование образовательной программы (профиля/направленности/специализации)"
            v-model="addingProgramForm.eduProgramName"
          />

          <input-text
            label="Причина добавления образовательной программы"
            v-model="addingProgramForm.addReason"
            :max-length="250"
          />
        </div>
      </div>

      <div class="modal-box__bottom">
        <div class="line">
          <div class="line__item">
            <custom-button label="Отмена" @click="closeAddProgramModal" />
          </div>
          <div class="line__item line__item--mla">
            <custom-button
              label="Сохранить"
              @click="addTaskProgram"
              :disabled="!addProgramInputsFilled"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import CustomButton from '@/components/CustomButton.vue';
import CustomSelect from '@/components/CustomSelect.vue';
import { useRoute, useRouter } from 'vue-router';
import SvgIcon from '@/components/SvgIcon';
import SvgClr from '@/components/SvgClr';
import { Monitoring } from '@/repositories';
import FileAttach from '@/components/FileAttach';
import SwitchInput from '@/components/SwitchInput.vue';
import { downloadFile } from '@/utils/helpers/downloadFile';
import InputText from '@/components/InputText.vue';
import { MONITORING_STATUS_ID } from '@/constants/monitoring/status';
// import axios from '@/api/axios';
// import { getFileNameFromContentDisposition } from '@/utils/helpers/getFileNameFromContentDisposition';
import { createDetachedSignature, createHash, getUserCertificates, isValidSystemSetup } from "crypto-pro";

const router = useRouter();
const route = useRoute();
const store = useStore();

const eduFormats = [
  { text: 'Очная', value: 'Очная' },
  { text: 'Очно-заочная', value: 'Очно-заочная' },
  { text: 'Заочная', value: 'Заочная' },
];

const taskId = route.params.id;
const levelId = Number(route.query.levels[0]);

const isLoading = ref();
const notifyClass = ref('');

const isTaskCompleted = ref(false);
const isSendModalOpen = ref(false);
const taskStatusId = ref([]);
const organizationId = ref();
const file = ref();

const programs = ref([]);
const programsWithDVI = ref([]);
const activeProgramsWithDvi = ref([]);
const programsEduFormats = ref({});
const recruitBases = ref({});
const activeProgram = ref();

const ugsnIds = ref([]);
const licencedPrograms = ref([]);

/* Функционал добавления/замены/отказа от программ */
const isSaveButtonLoading = ref(false);

const isChoiceBetweenProgramModalOpen = ref(false);
const openChoiceBetweenProgramModal = () => {
  isChoiceBetweenProgramModalOpen.value = true;
};

const isAddBasicProgramModalOpen = ref(false);
const addingBasicProgramForm = ref({
  eduLevel: '',
});
const basicEduLevels = [
  { text: 'Начальное общее образование', value: 'Начальное общее образование' },
  { text: 'Основное общее образование', value: 'Основное общее образование' },
  { text: 'Среднее общее образование', value: 'Среднее общее образование' },
];

const isAddingBasicProgramFormFilled = computed(() => {
  return addingBasicProgramForm.value.eduLevel;
});

const openAddBasicProgramModal = () => {
  isChoiceBetweenProgramModalOpen.value = false;
  isAddBasicProgramModalOpen.value = true;
};

async function addBasicTaskProgram() {
  isSaveButtonLoading.value = true;
  try {
    await Monitoring.addBasicTaskProgram(taskId, addingBasicProgramForm.value.eduLevel);
  } catch (error) {
    window.alert(
      `Не удалось добавить образовательную программу для уровня ${addingBasicProgramForm.value}`
    );
  } finally {
    getPrograms();
    addingBasicProgramForm.value = {
      eduLevel: '',
    };
    isSaveButtonLoading.value = false;
    isAddBasicProgramModalOpen.value = false;
  }
}

const isAddProgramModalOpen = ref(false);
const addingProgramForm = ref({
  ugsnId: '',
  licencedProgramId: '',
  eduProgramName: '',
  addReason: '',
});

const addProgramInputsFilled = computed(() => {
  return (
    addingProgramForm.value.ugsnId &&
    addingProgramForm.value.licencedProgramId &&
    addingProgramForm.value.eduProgramName &&
    addingProgramForm.value.addReason
  );
});

const openAddProgramModal = () => {
  isChoiceBetweenProgramModalOpen.value = false;
  isAddProgramModalOpen.value = true;
  getProgramModalUgsnIds();
};

async function getProgramModalUgsnIds() {
  try {
    const response = await Monitoring.getUgsnIds();
    ugsnIds.value = response;
  } catch (error) {
    window.alert('Не удалось получилось список УГСН кодов для добавления программы');
  }
}

async function getLicensedProgramsByUgsnId(ugsnId) {
  try {
    const response = await Monitoring.getLicensedProgramsByUgsnId(ugsnId);
    licencedPrograms.value = response;
  } catch (error) {
    window.alert('Не удалось получилось список лицензированных программ по УГСН коду');
  }
}

const closeAddProgramModal = () => {
  isAddProgramModalOpen.value = false;
  addingProgramForm.value = {
    ugsnId: '',
    licencedProgramId: '',
    eduProgramName: '',
    addReason: '',
  };
};

async function addTaskProgram() {
  isSaveButtonLoading.value = true;
  try {
    await Monitoring.addTaskProgram(taskId, addingProgramForm.value);
  } catch (error) {
    window.alert('Не удалось добавить образовательную программу');
  } finally {
    getPrograms();
    addingProgramForm.value = {
      ugsnId: '',
      licencedProgramId: '',
      eduProgramName: '',
      addReason: '',
    };
    isSaveButtonLoading.value = false;
    isAddProgramModalOpen.value = false;
  }
}

/* ФУНКЦИОНАЛ ДОБАВЛЕНИЯ/ЗАМЕНЫ/ОТКАЗА ОТ ПРОГРАММ */
const isReplaceProgramModalOpen = ref(false);
const replacingProgramForm = ref({
  licencedProgramId: '',
  eduProgramName: '',
  replaceReason: '',
});

const replaceProgramInputsFilled = computed(() => {
  return replacingProgramForm.value.eduProgramName && replacingProgramForm.value.replaceReason;
});

async function openReplaceProgramModal(program) {
  activeProgram.value = program;
  await getLicensedPrograms();
  isReplaceProgramModalOpen.value = true;
}

async function getLicensedPrograms() {
  try {
    const response = await Monitoring.getLicensedPrograms(
      organizationId.value,
      activeProgram.value.edu_level_id
    );

    const activeProgramItem = {
      text: `${activeProgram.value.licensed_program_code} ${activeProgram.value.licensed_program_name}`,
      value: `${activeProgram.value.licensed_program_id}`,
    };

    const isActiveProgramInResponse = response.find(
      (program) => program.text === activeProgramItem.text
    );

    if (!isActiveProgramInResponse) {
      response.unshift(activeProgramItem);
    }

    licencedPrograms.value = response;
  } catch (error) {
    window.alert('Не удалось получилось список лицензированных программ');
  }
}

const closeReplaceProgramModal = () => {
  isReplaceProgramModalOpen.value = false;
  replacingProgramForm.value = {
    licencedProgramId: '',
    eduProgramName: '',
    replaceReason: '',
  };
};

async function replaceTaskProgram() {
  isSaveButtonLoading.value = true;
  try {
    await Monitoring.replaceTaskProgram(activeProgram.value.id, replacingProgramForm.value);
  } catch (error) {
    window.alert('Не удалось заменить образовательную программу');
  } finally {
    getPrograms();
    replacingProgramForm.value = {
      licencedProgramId: '',
      eduProgramName: '',
      replaceReason: '',
    };
    isSaveButtonLoading.value = false;
    isReplaceProgramModalOpen.value = false;
  }
}

const isRejectProgramModalOpen = ref(false);
const rejectingProgramForm = ref({
  rejectReason: '',
});

const rejectProgramInputsFilled = computed(() => {
  return rejectingProgramForm.value.rejectReason;
});

const openRejectProgramModal = (program) => {
  isRejectProgramModalOpen.value = true;
  activeProgram.value = program;
};

const closeRejectProgramModal = () => {
  isRejectProgramModalOpen.value = false;
  rejectingProgramForm.value = {
    rejectReason: '',
  };
};

async function rejectTaskProgram() {
  isSaveButtonLoading.value = true;
  try {
    await Monitoring.rejectTaskProgram(
      activeProgram.value.id,
      !activeProgram.value.is_rejected,
      rejectingProgramForm.value.rejectReason
    );
  } catch (error) {
    window.alert('Не удалось отказаться от образовательной программы');
  } finally {
    getPrograms();
    rejectingProgramForm.value = {
      rejectReason: '',
    };
    isSaveButtonLoading.value = false;
    isRejectProgramModalOpen.value = false;
  }
}

const redirectToProgramsMarks = (id) => {
  if (store.state.user.isExternal) {
    router.push({ name: 'external.monitoring.program-marks', params: { id: id } });
  } else {
    router.push({
      name: 'monitoring.program-marks',
      params: { id },
    });
  }
};

//Вспомогательные функции
const isHigherLevel = (level) => level === 1;
const isSecondaryVocationalLevel = (level) => level === 5;
const isHigherOrSecondaryVocationalLevel = (level) =>
  isHigherLevel(level) || isSecondaryVocationalLevel(level);
const isGeneralLevel = (level) => level === 2 || level === 3 || level === 4;
const isSecondaryVocationalOrSchoolLevel = (level) => level === 5 || isGeneralLevel(level);

//Функционал обновления поля "Наличие набора в 2022 году"
const programsAdmissions = ref({});

const admissionFormats = [
  { text: 'Нет', value: 'Нет' },
  { text: 'Да', value: 'Да' },
];

const demoFormats = [
  { text: 'Нет', value: 'Нет' },
  { text: 'Да', value: 'Да' },
];

const recruitBase = [
  { text: '9 классов', value: '9 классов' },
  { text: '11 классов', value: '11 классов' },
];

async function updateAdmission(programId, admission) {
  try {
    await Monitoring.updateAdmission(programId, admission);
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    window.alert('Не удалось поменять значение поля "Наличие набора"');
    programsAdmissions.value[programId] = '';
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
}

//Функционал обновления поля "Наличие выпуска в 2021 году (для ВО и СПО)"
const programsGraduationsIn2021 = ref({});

const graduationFormats = [
  { text: 'Нет', value: 'Нет' },
  { text: 'Да', value: 'Да' },
];

async function updateGraduationIn2021(programId, graduationIn2021) {
  try {
    await Monitoring.updateGraduationIn2021(programId, graduationIn2021);
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    window.alert('Не удалось поменять значение поля "Наличие выпуска в 2021 году (для ВО и СПО)"');
    programsGraduationsIn2021.value[programId] = '';
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
}

//Функционал обновления поля "Наличие выпуска в 2022 году (для СПО и школы)"
const programsGraduationsIn2022 = ref({});

async function updateGraduationIn2022(program, newGraduationIn2022Value) {
  // Блокировка селекта Демо при выбранном "Нет" в селекте "Выпуск 2022" для СПО
  if (newGraduationIn2022Value === 'Нет') {
    programDemos.value[program.id] = '';
    blockedDemos.value[program.id] = true;
    updateDemo(program.id, '');
  } else {
    blockedDemos.value[program.id] = false;
  }
  try {
    await Monitoring.updateGraduationIn2022(program.id, newGraduationIn2022Value);
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    window.alert(
      'Не удалось поменять значение поля "Наличие выпуска в 2022 году (для СПО и школы)"'
    );
    programsGraduationsIn2022.value[program.id] = '';
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
}

//Функционал обновления поля "Демо (для СПО)"
const programDemos = ref({});
const blockedDemos = ref({});

async function updateDemo(programId, demo) {
  try {
    await Monitoring.updateDemo(programId, demo);
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    window.alert('Не удалось поменять значение поля "Демо (для СПО)"');
    programDemos.value[programId] = '';
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
}

// Функционал блокировки кнопки "Заполнить"
const isAdmissionNotFilled = (program) =>
  !programsAdmissions.value[program.id] || programsAdmissions.value[program.id] === 'Нет';

const isGraduationIn2021NotFilled = (program) =>
  !programsGraduationsIn2021.value[program.id] ||
  programsGraduationsIn2021.value[program.id] === 'Нет';

const isGraduationIn2022NotFilled = (program) =>
  !programsGraduationsIn2022.value[program.id] ||
  programsGraduationsIn2022.value[program.id] === 'Нет';

//Если у программы уровень - ВО/СПО и при этом отсутствует наименование, кнопка заблокирована
const isEduProgramNameNotFilled = (program) =>
  isHigherOrSecondaryVocationalLevel(program.level_id) && !program.edu_program_name;

//Если у программы уровень - ВО и при этом в столбцах "Наличие набора в 2022 году" и "Наличие выпуска в 2021 году (для ВО и СПО)" не выбрано "Да", кнопка заблокирована
const isHigherLevelAdmissionOrGraduationIn2021NotFilled = (program) => {
  if (
    isHigherLevel(program.level_id) &&
    (isAdmissionNotFilled(program) || isGraduationIn2021NotFilled(program))
  ) {
    return true;
  }
};

const isFillButtonDisabledForSecondaryVocationalLevel = (program) => {
  if (isSecondaryVocationalLevel(program.level_id)) {
    // «Наличие выпуска в 2021 году (ВО, СПО)» - «Да»
    if (isGraduationIn2021NotFilled(program)) {
      return true;
      // «Наличие выпуска в 2022 году (СПО, общее образование)»- «Да» или "Нет"
    } else if (!programsGraduationsIn2022.value[program.id]) {
      return true;
    } else if (
      // Если «Наличие выпуска в 2022 году (СПО, общее образование)» «Да», то должно быть выбрано «Демо»
      programsGraduationsIn2022.value[program.id] === 'Да' &&
      !programDemos.value[program.id]
    ) {
      return true;
      // «База набора» - «9 классов» или «11 классов»
    } else if (!recruitBases.value[program.id]) {
      return true;
    }
    return false;
  }
};

const isFillButtonDisabledForGeneralLevel = (program) => {
  if (isGeneralLevel(program.level_id)) {
    if (isGraduationIn2022NotFilled(program)) {
      return true;
    }
    return false;
  }
};

const showSuccessNotify = () => {
  notifyClass.value = 'r-notify--success';
};

const showErrorNotify = () => {
  notifyClass.value = 'r-notify--error';
};

const hideNotify = () => {
  notifyClass.value = '';
};

async function updateDvi(programId, turnOnDvi) {
  try {
    await Monitoring.updateDvi(programId, turnOnDvi);
    programsWithDVI.value = activeProgramsWithDvi.value;
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    window.alert('Не удалось поменять показатель ДВИ');

    if (turnOnDvi) {
      activeProgramsWithDvi.value = activeProgramsWithDvi.value.filter(
        (program) => program !== programId
      );
    } else {
      activeProgramsWithDvi.value = programsWithDVI.value;
    }
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
}

async function updateEduFormat(programId, eduFormat) {
  try {
    await Monitoring.updateEduFormat(programId, eduFormat);
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    window.alert('Не удалось поменять формат обучения');
    programsEduFormats.value[programId] = '';
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
}

async function updateRecruitBase(programId, recruitBase) {
  try {
    await Monitoring.updateRecruitBase(programId, recruitBase);
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    window.alert('Не удалось поменять формат обучения');
    recruitBases.value[programId] = '';
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
}

// const adaptedPrograms = ref([]);
// const activeAdaptedPrograms = ref([]);

/* async function updateIsAdaptedFlag(programId, isAdapted) {
  try {
    await Monitoring.updateIsAdaptedFlag(programId, isAdapted);
    adaptedPrograms.value = activeAdaptedPrograms.value;
    setTimeout(showSuccessNotify, 1000);
    setTimeout(hideNotify, 6000);
  } catch (error) {
    console.error(error);
    window.alert('Не удалось поменять показатель "Адаптированная"');

    if (isAdapted) {
      activeAdaptedPrograms.value = activeAdaptedPrograms.value.filter(
        (program) => program !== programId
      );
    } else {
      activeAdaptedPrograms.value = adaptedPrograms.value;
    }
    setTimeout(showErrorNotify, 1000);
    setTimeout(hideNotify, 6000);
  }
} */

async function generateDataReportTemplate(programId) {
  try {
    const template = await Monitoring.getMonitoringDataReportTemplate(programId);
    downloadFile(template, template.name);
  } catch (error) {
    console.error(error);
    window.alert('Не удалось сформировать отчет "Данные аккредитационного мониторинга"');
  }
}

async function generateMonitoringResultReportTemplate(programId) {
  try {
    const template = await Monitoring.getMonitoringResultReportTemplate(programId);
    downloadFile(template, template.name);
  } catch (error) {
    window.alert('Не удалось сформировать итоговый отчёт');
  }
}

// async function closeTask() {
//   try {
//     await Monitoring.closeTask(taskId);
//     await getTaskStatus();
//     isSendModalOpen.value = false;
//   } catch (error) {
//     window.alert('Не удалось завершить задачу мониторинга');
//   }
// }

const openSendModal = (program) => {
  isSendModalOpen.value = true;
  activeProgram.value = program;

  if (!cryptoPro.isLoaded) {
    loadCryptoPro();
  } else {
    loadCertificates();
  }
};

async function sendProgram(programId) {
  isSaveButtonLoading.value = true;
  try {
    const payload = new FormData();
    payload.append('report', file.value, file.value.name);

    const signature = await signReport();
    if (signature) {
      payload.append('signature', signature);
    }

    await Monitoring.sendProgram(programId, payload);
    await getPrograms();

    isSendModalOpen.value = false;
  } catch (error) {
    console.error(error);
    window.alert('Не удалось завершить программу');
  } finally {
    file.value = null;
    isSaveButtonLoading.value = false;
  }
}

async function signReport() {
  const shouldBeSigned = !!certificateThumbprint.value;
  if (!file.value || !shouldBeSigned) {
    return;
  }

  const message = await file.value.arrayBuffer();
  return await sign(message, certificateThumbprint.value);
}

// async function getTaskStatus() {
//   try {
//     const response = await Monitoring.getTask(taskId);
//     taskStatusId.value = response.status.id;
//   } catch (error) {
//     window.alert('Не удалось загрузить статус задачи');
//   } finally {
//     isLoading.value = false;
//   }
// }

async function getPrograms() {
  isLoading.value = true;
  try {
    const levels = Array.isArray(route.query.levels) ? route.query.levels : [route.query.levels];
    const response = await Monitoring.getPrograms(taskId, levels);
    programs.value = response.programs.sort((a, b) => a.id - b.id);
    programsWithDVI.value = response.programs.map((program) => (program.dvi ? program.id : ''));
    activeProgramsWithDvi.value = programsWithDVI.value;

    /*     adaptedPrograms.value = response.programs.map((program) =>
      program.is_adapted ? program.id : ''
    );
    activeAdaptedPrograms.value = adaptedPrograms.value; */

    response.programs.forEach((program) => {
      programsEduFormats.value[program.id] = program.education_form;
      programsAdmissions.value[program.id] = program.admission;
      programsGraduationsIn2021.value[program.id] = program.graduation_in_2021;
      programsGraduationsIn2022.value[program.id] = program.graduation_in_2022;
      programDemos.value[program.id] = program.demo;
      blockedDemos.value[program.id] = program.graduation_in_2022 === 'Нет';
      recruitBases.value[program.id] = program.recruit_base;
    });

    await getTask();
  } catch (error) {
    window.alert('Не удалось загрузить список выбранных программ');
  } finally {
    isLoading.value = false;
  }
}

const isTaskFinished = ref(false);
async function getTask() {
  isLoading.value = true;
  try {
    const response = await Monitoring.getTask(taskId);
    taskStatusId.value = response.status_id;
    isTaskFinished.value = response.status_id === MONITORING_STATUS_ID.FINISHED;
    isTaskCompleted.value = response.completion_percent === 100;
    organizationId.value = response.organizationId;
  } catch (error) {
    window.alert('Не удалось загрузить данные мониторинга');
  } finally {
    isLoading.value = false;
  }
}

onBeforeMount(() => {
  isLoading.value = true;
});

onMounted(() => {
  getPrograms();
});

// async function downloadRecommendations(program) {
//   const response = await axios.get(`monitoring/programs/${program.id}/recommendations`, {
//     responseType: 'blob',
//   });
//   downloadFile(
//     response.data,
//     getFileNameFromContentDisposition(response.headers['content-disposition'])
//   );
// }

// Работа с ЭЦП
/**
 * @type {UnwrapNestedRefs<{
 * isLoading: boolean,
 * isLoaded: boolean
 * error: string|null,
 * isValidSystemSetup: boolean,
 * certificates: {
 *   isLoading: boolean,
 *   data: Certificate[],
 *   error: string|null,
 *   isLoaded: boolean
 * },
 * }>}
 */
const cryptoPro = reactive({
  isLoaded: false,
  isLoading: false,
  error: null,
  isValidSystemSetup: false,
  certificates: {
    isLoaded: false,
    isLoading: false,
    error: null,
    data: [],
  },
});

async function loadCryptoPro() {
  cryptoPro.isLoading = true;
  try {
    cryptoPro.isValidSystemSetup = await isValidSystemSetup();
  } catch (e) {
    console.error(e);
    cryptoPro.error = 'Не удалось загрузить плагин для подписания отчета';
  } finally {
    cryptoPro.isLoading = false;
    cryptoPro.isLoaded = true;
  }

  if (!cryptoPro.isValidSystemSetup) {
    return;
  }

  await loadCertificates();
}
/** @type {ToRef<string|null>} */
const certificateThumbprint = ref(null);

async function loadCertificates() {
  cryptoPro.certificates.isLoading = true;
  certificateThumbprint.value = null;
  try {
    cryptoPro.certificates.data = (await getUserCertificates()) ?? [];
  } catch (e) {
    console.error(e);
    cryptoPro.certificates.error =
      'Не удалось получить список сертификатов для подписи отчета. Попробуйте, пожалуйста, позже!';
  } finally {
    cryptoPro.certificates.isLoading = false;
    cryptoPro.certificates.isLoaded = true;
  }
}

/**
 * @param {string|ArrayBuffer} message
 * @param {string} thumbprint
 * @returns {Promise<string>}
 * @throws Error
 */
async function sign(message, thumbprint) {
  const hash = await createHash(message);
  return await createDetachedSignature(thumbprint, hash);
}

/**
 * @param {Certificate} certificate
 * @returns {{value: string, text: string}}
 */
function certificateToOption(certificate) {
  return {
    value: certificate.thumbprint,
    text: certificate.name,
  };
}
</script>
